import { connect } from 'react-redux';
import StartKiosk from './StartKiosk';
import actions from '../../actions';
import { opportunitiesSelector } from './selectors';
import { startKiosk } from '../../kiosk/actions';

const mapStateToProps =  state => ({
    ...opportunitiesSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getOpportunities: () => dispatch(actions.opportunities.request()),
    startKioskMode: (opportunity) => dispatch( startKiosk(opportunity) )
});

export default connect(mapStateToProps, mapDispatchToProps)(StartKiosk);

import React, { useState } from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import LeaveKioskDialog from '../LeaveKioskDialog';
import blueLogo from '../../../images/blueLogo.png';
import FullscreenButton from 'kiosk/fullscreen/FullscreenButton';

const useStyles = makeStyles(theme => ({
    flexGrow: {
        flexGrow: 1
    },
    bottomMenu: {
        padding: theme.spacing(2),
        marginTop: 'auto',
    },
    actionButton: {
        margin: '30px 60px 0px 10px',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#7D98DA'
    },
    bottom: { 
        marginBottom: '40px',
        float: 'right' 
    },
    logo: {
        margin: '0px 60px 0'
    }
}))

export default function StopKioskFooter(props) {
    const { kioskStatus, error, stopKiosk } = props;

    const [showLeaveDialog, setShowLeaveDialog] = useState(false);
    const handleShow = () => setShowLeaveDialog(true);
    const handleHide = () => setShowLeaveDialog(false);
    const classes = useStyles();

    return (<>
        <Grid container justify={'center'} align="center" className={classes.bottomMenu}>
            <Grid item xs={1}>
                <img src={blueLogo} className={cn(classes.actionButton, classes.logo)} alt="Logo" />
            </Grid>
            <div className={classes.flexGrow} />
            <Grid item xs={3}>
                <div className={classes.bottom}>
                    <FullscreenButton />
                    <span onClick={handleShow} className={classes.actionButton}>
                        Leave Kiosk Mode
                    </span>
                </div>
            </Grid>
            <LeaveKioskDialog 
                open={showLeaveDialog} 
                onCancel={handleHide}
                onSubmit={stopKiosk}
                submitting={kioskStatus === 'stopping'}
                error={error}
            />
        </Grid>
    </>)
}
import { takeEvery, put } from 'redux-saga/effects';
import actions from '../actions';
import { VOLUNTEERS_REQUEST } from '../actions/volunteers';
import { API, Auth as AuthAmplify } from 'aws-amplify';
import { get } from 'lodash';

export function* requestVolunteers() {
  try {
    const permissions = yield AuthAmplify.currentAuthenticatedUser().then(user =>
      JSON.parse(get(user, 'attributes.custom:permissions'))
    );
    const organizationId = get(permissions, 'managedOrganization.id');
    const volunteers = yield API.get('civicChampsApi', `/organizations/${organizationId}/volunteers?reporting=true`);
    console.log(volunteers);
    yield put(actions.volunteers.success(volunteers));
  } catch (e) {
    yield put(actions.volunteers.failure());
  }
}

export default function* volunteersSaga() {
  yield takeEvery(VOLUNTEERS_REQUEST, requestVolunteers);
}

import _ from 'lodash';
import { connect } from 'react-redux';

import KioskSignUp from './KioskSignUp';
import { signUpVolunteer, signUpVolunteerFailed } from '../actions';
import { organizationName } from '../selectors';

const mapStateToProps = state => ({
    organizationName: organizationName(state),
    submitting: _.get(state, 'kiosk.signUpVolunteer.running', false), // TODO move to selector
    error: _.get(state, 'kiosk.signUpVolunteer.error'),
});

const mapDispatchToProps = dispatch => ({
    signUpVolunteer: (signUpData) => dispatch( signUpVolunteer(signUpData) ),
    setSignUpError: (msg) => dispatch( signUpVolunteerFailed(msg) )
});

export default connect(mapStateToProps, mapDispatchToProps)(KioskSignUp);
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Dialog, Typography, Button } from '@material-ui/core';

import Loading from '../../components/Loading';
import KioskCheckIn from '../KioskCheckIn';
import KioskSignUp from '../KioskSignUp';
import StopKioskFooter from './StopKioskFooter';
import useStyles from './useStyles';

export default function StandaloneKiosk(props) {
    const classes = useStyles();

    return (
        <Dialog 
            fullScreen 
            open={props.isKioskMode}
            className={classes.diologWrapper} //sic
        >
            {renderContent(props)}
        </Dialog>
    );
}

function renderContent(props) {
    const { opportunityName, kioskStatus, error, abortKiosk } = props;
    switch(kioskStatus) {
        case 'starting':
            return <Loading /> //TODO make sure this is centered
        case 'stopping':
        case 'active':
            return (<>
                <Switch>
                    <Route path="/kiosk/signUp" component={KioskSignUp} />
                    <Route path="/kiosk" component={KioskCheckIn} />
                    <Redirect to="/kiosk" />
                </Switch>
                <StopKioskFooter />
            </>)
        case 'failed':
            return (<>
                <Typography variant="h2" gutterBottom>
                    Oops!  An Error Occurred
                </Typography>
                <Typography variant="subtitle2">
                    {`We could not start a kiosk for the opportunity "${opportunityName}" at this time.`} 
                    Please try again later and contact 
                    &nbsp;<a href="mailto:support@civicchamps.com">Customer Support</a>&nbsp;
                    if the problem persists.
                </Typography>
                <Typography color="error">{error}</Typography>
                <Button onClick={abortKiosk}>
                    Back
                </Button>
            </>)
        default:
            return null;
    }
}

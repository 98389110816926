import { take, fork } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import authSaga from '../auth/saga';
import opportunitiesSaga from './opportunities';
import volunteersSaga from './volunteers';
import activitiesSaga from './activities';
import kioskModeSaga from '../kiosk/sagas';
import contributionsSaga from './contributions';
import { rootSaga as fullscreenSaga } from '../kiosk/fullscreen/sagas';
import teamSaga from '../members/team/saga';
import apiSaga from '../api/saga';

export default function* rootSaga() {
  yield fork(authSaga);

  console.log('waiting for rehydration');
  yield take(REHYDRATE);
  console.log('rehydrated');

  yield fork(opportunitiesSaga);
  yield fork(volunteersSaga);
  yield fork(activitiesSaga);
  yield fork(kioskModeSaga);
  yield fork(fullscreenSaga);
  yield fork(contributionsSaga);
  yield fork(teamSaga);
  yield fork(apiSaga);
}

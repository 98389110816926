import opportunities from './opportunities';
import volunteers from './volunteers';
import activities from './activities';
import givingOrgs from './givingOrgs';
export default {
  opportunities,
  volunteers,
  activities,
  givingOrgs
};

import _ from 'lodash';
import moment from 'moment';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { API } from 'aws-amplify';

import getQueryKey from './utils/getQueryKey';
import { RUN_QUERY, startRequest, querySucceeded, queryFailed } from './actions';

const CACHE_MISS = 'cache.miss';
const CACHE_STALE = 'cache.stale';
const CACHE_HIT = 'cache.hit';

export default function* watchRunQuery() {
    yield takeEvery(RUN_QUERY, onRunQuery);
}

export function* onRunQuery(runQueryAction) {
    let { queryConfig } = runQueryAction.payload; //TODO don't try to double fetch?
    let { request, onSuccess, onError } = queryConfig;

    let cacheResult = yield call(checkCache, queryConfig);

    if(cacheResult === CACHE_HIT) return;

    try {
        yield put( startRequest(queryConfig) );
        const { method='get', url, config } = request;
        const response = yield call([API, method], 'civicChampsApi', url, config);
        yield put( querySucceeded(queryConfig, response) ); 
        if(onSuccess) onSuccess(response);
    } catch(error) {
        yield put( queryFailed(queryConfig, error) );
        if(onError) onError(error);
    }
}

//TODO move to its own file?
const parseDuration = text => {
    let [num, unit] = text.split(/\s+/g);
    return moment.duration( parseFloat(num), unit);
}

function* checkCache(queryConfig) {
    let { caching } = queryConfig;
    if(!caching) {
        return CACHE_MISS;
    } 

    const maxAge = parseDuration(caching.maxAge);
    const staleAfter = parseDuration(caching.staleAfter);

    let queryKey = getQueryKey(queryConfig);
    const query = yield select( state => state.api.queries[queryKey] );

    const prevCompletedAt = _.get(query, 'prev.completedAt');

    if(!query || (query.running && !prevCompletedAt) ) return CACHE_MISS;

    const now = moment();
    const completedAt = moment(query.completedAt || prevCompletedAt);
    const duration = moment.duration( now.diff(completedAt) );

    if( maxAge.isValid() && duration > maxAge) {
        return CACHE_MISS;
    } else { 
        let isStale = staleAfter.isValid() && duration > staleAfter;
        return isStale ? CACHE_STALE : CACHE_HIT;
    }
}
import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef, useState, useMemo, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';

import Loading from 'components/Loading';
import DurationField from './DurationField';
import VolunteeringRoleSelect from '../../../components/VolunteeringRoleSelect';
import LabeledText from './LabeledText';
import useDateTimeRange from 'utils/useDateTimeRange';
import { useMutation, useQuery } from 'api/hooks';
import { UPDATE_ACTIVITY } from '../queries';
import { GET_VOLUNTEERING_ROLES } from '../../volunteeringRoles/queries';
import { getVolunteerName, getOpportunityName, isTimesheetActivity } from '../utils';
import { useCurrentOrg } from 'auth/hooks';

const useStyles = makeStyles(theme => ({
  labelStyle: {
    fontSize: '1.2em',
    fontWeight: '600',
    display: 'inline',
  },
  textStyle: {
    fontSize: '1.2em',
    display: 'inline',
    paddingLeft: '5px',
  },
  rowContent: {
    padding:'5% 1%'
  },
  rowContentCentered: {
    padding:'1% 10%',
    alignContent: 'center',
  },
  dtPicker:{
  },
  submitButton: {
    backgroundColor: '#7D98DA',
    color: 'white'
  },
  buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding:'0',
    fontFamily: 'Open Sans',
    borderRadius:'4px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'white',
  },
  title: {
    fontSize:'2em',
    fontWeight: '500',
    backgroundColor: '#7D98DA',
    color: 'white',
    marginTop:'0px',
    width:'100%',
    padding:'2% 5%',
  },

});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: '10px 30px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useActivityTimeRange = (activity) => {
    const retVal = useDateTimeRange();
    const [, actions] = retVal;

    useEffect( () => {
        let start = moment( _.get(activity, 'occurredAt') );
        let hours =  _.get(activity, 'hoursVolunteered', 0);
        let duration = moment.duration(hours, 'hours');

        actions.setStart(start);
        actions.setDuration(duration);
    }, [activity, actions]);

    return retVal;
}

const useActivityForm = (activity) => {
    const [ range, rangeActions ] = useActivityTimeRange(activity);
    const [ role, setRole ] = useState(null);
    const [ team, setTeam ] = useState('');

    const actions = useMemo( () => ({
        setStartedAt: rangeActions.setStart,
        setEndedAt: rangeActions.setEnd,
        setDuration: rangeActions.setDuration,
        setRole,
        setTeam,
    }), [rangeActions]);

    useEffect( () => {
        let initialRole = _.get(activity, 'volunteeringRole');
        let initialTeam = _.get(activity, 'team');

        setRole(initialRole);
        setTeam(initialTeam || '');
    }, [activity])

    return useMemo( () => {
        const state = {
            startedAt: range.start,
            endedAt: range.end,
            duration: range.duration,
            role,
            team
        };

        return [state, actions];
    }, [range, role, team, actions])
}

const useVolunteeringRoles = () => {
    //TODO this should figure out if we have to use an opportunity config, or just all roles!
    const organization = useCurrentOrg();
    const params = useMemo(
        () => ({ variables: { organization } }),
        [organization]
    );
    return useQuery(GET_VOLUNTEERING_ROLES, params);
}

export default function EditActivity(props) {
    const { open, onClose, activity } = props;
    const classes = useStyles();
    const [state, actions] = useActivityForm(activity);

    const { data: roles, loading: loadingRoles, error: rolesError } = useVolunteeringRoles();

    const [updateActivity, { error: submitError, loading: submitting }] = useMutation(UPDATE_ACTIVITY);
    const submit = useCallback( () => {
        let activityId = activity.id;
        let updates = {
          occurredAt: state.startedAt,
          hoursVolunteered: state.duration.as('hours'),
          volunteeringRoleId: _.get(state.role, 'id'),
          team: state.team
        };

        updateActivity({
          variables: { activityId, updates },
          onSuccess: onClose
        })
    }, [activity, state, updateActivity, onClose]);

    const volunteer = getVolunteerName(activity);
    const opportunity = getOpportunityName(activity);

    //TODO if no roles and loading... show a spinner
    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableBackdropClick={true}
            TransitionComponent={Transition}
            className={classes.modalStyle}
        >
            <form>
                <DialogTitle>Edit Activity</DialogTitle>
                <DialogContent>
                    { loadingRoles ? <Loading />
                    : <Grid>
                        <Grid item className={classes.rowContent}>
                            <LabeledText
                                label="Volunteer:"
                                text={volunteer}
                                labelStyle={classes.labelStyle}
                                textStyle={classes.textStyle}
                            />
                        </Grid>
                        { opportunity && <Grid item className={classes.rowContent}>
                            <LabeledText
                                label="Opportunity:"
                                text={opportunity}
                                labelStyle={classes.labelStyle}
                                textStyle={classes.textStyle}
                            />
                        </Grid>}
                        { isTimesheetActivity(activity)
                          ? <>
                              <Grid item className={classes.rowContent}>
                                  <KeyboardDateTimePicker
                                      name="startedAt"
                                      label="Check-In"
                                      value={state.startedAt}
                                      onChange={actions.setStartedAt}
                                      format="MM/DD/YYYY hh:mm a"
                                      inputVariant="outlined"
                                      className = {classes.dtPicker}
                                      disabled={submitting}
                                  />
                              </Grid>
                              <Grid item className={classes.rowContent}>
                                  <KeyboardDateTimePicker
                                      name="endedAt"
                                      label="Check-Out"
                                      minDate={state.startedAt}
                                      value={state.endedAt}
                                      onChange={actions.setEndedAt}
                                      format="MM/DD/YYYY hh:mm a"
                                      inputVariant="outlined"
                                      className = {classes.dtPicker}
                                      disabled={submitting}
                                  />
                              </Grid>
                            </>
                          : <Grid item className={classes.rowContent}>
                              <KeyboardDatePicker
                                name="startedAt"
                                label="Occurred On"
                                value={state.startedAt}
                                onChange={actions.setStartedAt}
                                format="MM/DD/YYYY"
                                inputVariant="outlined"
                                className = {classes.dtPicker}
                                disabled={submitting}
                              />
                            </Grid>
                        }
                      <Grid item className={classes.rowContentCentered}>
                            <DurationField
                                value={state.duration}
                                onChange={actions.setDuration}
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item className={classes.rowContent}>
                            <VolunteeringRoleSelect
                                roles={roles}
                                value={state.role}
                                onChange={actions.setRole}
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item className={classes.rowContent}>
                            <TextField
                                id="team"
                                name="team"
                                label="Team"
                                variant="outlined"
                                value={state.team}
                                onChange={e => actions.setTeam(e.target.value)}
                                disabled={submitting}
                                fullWidth
                            />
                        </Grid>
                        { submitError && <Grid item className={classes.rowContent}>
                            <Typography variant="error">{submitError}</Typography>
                          </Grid>}
                    </Grid>}
                </DialogContent>
                <DialogActions>
                    <Button
                      onClick={onClose}
                      disabled={submitting}
                    >
                        Cancel
                    </Button>
                    <Button
                      className={classes.submitButton}
                      disabled={submitting || loadingRoles}
                      onClick={submit}
                    >
                        {submitting && <CircularProgress className={classes.buttonProgress} size={24} color="primary"/>}
                        Save Activity
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';

const getValueOrEmpty = value => value === 0 ? '' : value;
const getMinutes = value => getValueOrEmpty(moment.duration(value).get('minutes'));
const getHours = value => getValueOrEmpty(Math.floor( moment.duration(value).as('hours') ));

const useStyles = makeStyles(theme => ({
  timeStyle: {
    fontSize: '1.2em',
    fontWeight: '400',
    textAlign: 'center',
    width:'50px',
    paddingLeft:'20px',
  },
}));


export default function DurationField(props) {
    const { value, onChange, disabled } = props;
    const classes = useStyles();

    const [hours, setHours] = useState( () => getHours(value) );
    const [minutes, setMinutes] = useState( () => getMinutes(value) );

    useEffect( () => {
        setHours( getHours(value) );
        setMinutes( getMinutes(value) );
    }, [value, setHours, setMinutes])

    useEffect( () => {
        if(onChange) {
            let newValue = moment.duration({ hours, minutes });
            onChange(newValue);
        }

    }, [minutes, hours, onChange])

    const handleHoursChange = useCallback(e => setHours(e.target.value), [setHours]);
    const handleMinutesChange = useCallback(e => setMinutes(e.target.value), [setMinutes]);

    return (<>
        <Input
            name="hours"
            value={hours}
            onChange={handleHoursChange}
            disabled={disabled}
            className={classes.timeStyle}
            placeholder={"0"}
        />
        hours
        <Input
            name="minutes"
            value={minutes}
            onChange={handleMinutesChange}
            disabled={disabled}
            className={classes.timeStyle}
            placeholder={"0"}
        />
        minutes
    </>);
}

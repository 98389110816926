export default function parseUsername(username) {
    if( /^[-()\d\s]+$/.test(username) ) {
        let phone = username.replace(/[^\d]+/g, '');
        if( phone.length === 10) {
            username = '+1' + phone;
        } else if(phone.length >= 11) {
            username = '+' + phone;
        }
    }

    return username;
}
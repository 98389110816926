import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ToggleButton from './ToggleButton';
import UserSearch from './UserSearch';
import useStyles from  './useStyles';
import CheckOutDialog from './CheckOutDialog';
import useMiniSearch from 'utils/useMiniSearch';

const timesheetSearchConfig = {
    fields: ['user.givenName', 'user.familyName', 'user.email', 'user.phoneNumber'],
    extractField: _.get,
    processTerm: (term, field)  => 
        field === 'user.phoneNumber' 
            ? term.substring(2)
            : term.toLowerCase(),
    searchOptions: {
        processTerm: term => term.toLowerCase(),
        prefix: true
    }    
}

export default function KioskCheckIn(props) {
    const { 
        organizationName,
        volunteeringRoles,
        timesheetMode,
        volunteers,
        setTimesheetMode,
        checkIn,
        checkOut
    } = props;

    const [selected, setSelected] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [query, setQuery] = useState('');
    const search = useMiniSearch(volunteers, timesheetSearchConfig);
    const classes = useStyles();

    const handleSetMode = (mode) => {
        if(mode !== timesheetMode) {
            setQuery('');
            setSelected(null);
        }

        setTimesheetMode(mode);
    }

    const handleSelect = (volunteer) => {
        setSelected(volunteer !== selected ? volunteer : null)
    }

    const handleSubmitUser = () => {
        if(timesheetMode === 'check-in') {
            checkIn(selected);
            setSelected(null);
            setQuery('');
        } else {
            setShowDialog(true);
        }
    }

    const handleSubmitCheckOut = (checkOutData) => {
        checkOut(selected, checkOutData);
        setSelected(null);
        setQuery('');
        setShowDialog(false);
    }

    return (<>
        <Grid container justify="flex-end">
            <Link 
                to="/kiosk/signUp"
                className={classes.actionButton}>
                Sign Up
            </Link>
        </Grid>
        <Container maxWidth="md" className={classes.containerWrapper}>
            <Grid container direction="column" justify="center" align="center">
                <Grid item xs>
                    <Typography variant="h4" gutterBottom>
                        {organizationName}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom className={classes.subTitle}>
                        {`Type Name to ${timesheetMode === 'check-in' ? 'Check In' : 'Check Out'}`}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <ToggleButton alignment={timesheetMode} setAlignment={handleSetMode} />
                </Grid>
            </Grid>
            <UserSearch
                timesheetMode={timesheetMode}
                query={query}
                selected={selected}
                onChange={setQuery}
                onSearch={search}
                onSelect={handleSelect}
                onSubmit={handleSubmitUser} 
            />
        </Container>
        <CheckOutDialog 
            open={showDialog}
            onClose={() => setShowDialog(false)}
            volunteer={selected}
            volunteeringRoles={volunteeringRoles}
            onSubmit={handleSubmitCheckOut}
        />
    </>)
}
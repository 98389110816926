
export const GET_VOLUNTEERING_ROLES = {
    request: (params) => ({
        url: `/organizations/${params.organization.id}/roles`
    }),
    caching: {
        maxAge: '1 day',
        staleAfter: '30 minutes'
    },
    update: (queryConfig, state, result) => ({
        ...state,
        volunteeringRoles: result
    }),
    select: (queryConfig, state) => state.volunteeringRoles
}
import { isString, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ImportExport from '@material-ui/icons/ImportExport';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';

import ActionMenuItem from 'components/menu/ActionMenuItem';

const useStyles = makeStyles(theme => ({
    csvLink: {
        textDecoration: 'none',
        color: 'black'
    }
}));

export const useCurrentDateWithFilename = (filename, separator='_') => 
    useMemo( () => {
        if( !isString(filename) || isEmpty(filename) ) return null;

        const today = moment().format('YYYYMMDD');
        const match = filename.match(/^(.*)\.csv$/);
        const prefix = match ? match[1] : filename;

        return `${prefix}${separator}${today}.csv`;
    }, [filename, separator]);


export default function ExportCsvMenuItem({ data, headers, filename }) {
    const classes = useStyles();
    const finalFilename = useCurrentDateWithFilename(filename);

    return (
        <CSVLink 
            className={classes.csvLink}
            data={data}
            headers={headers}
            filename={finalFilename}
        >
            <ActionMenuItem>
                <ListItemIcon>
                    <ImportExport />
                </ListItemIcon>
                Export to CSV
            </ActionMenuItem>
        </CSVLink>
    );
}
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    '@global': {
        body: {
        backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(6)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    checkInColor: {
        backgroundColor: '#7D98DA'
    },
    checkIn: {
        width: '100%',
        height: 50,
        marginTop: 16,
        borderRadius: 25,
        background: '#7D98DA'
    },
    formWrapper: {
        width: '80%'
    },
    actionButton: {
        margin: '30px 60px 0',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#7D98DA'
    },
}));
import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withAuthenticator, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';
import { SnackbarProvider } from 'notistack';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ToolBar from '../components/ToolBar';
import DrawerBar from '../components/Drawer';
import Notifications from '../notifications/Notifications';
import Volunteers from '../containers/Volunteers';
import Opportunities from '../containers/Opportunities';
import MyTeam from '../members/team/components/MyTeam';
import Activities from '../tracking/activity/components/Activities';
import Kiosk from '../containers/Kiosk/StartKiosk.container'; //TODO should replace the old Kiosk..

import StandaloneKiosk from '../kiosk/StandaloneKiosk';
import CustomSignIn from '../auth/components/CustomSignIn';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: `24px 90px`,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 90px 24px 30px',
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  }
}));

const App = ({ isKioskMode, history, currentOrganization, currentUser }) => {
  const classes = useStyles();

  return (
    <ConnectedRouter history={history}>
      <SnackbarProvider maxSnacks={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Notifications />
          {isKioskMode 
          ? <StandaloneKiosk /> 
          : <div className={classes.root}>
              <CssBaseline />
              <AppBar position="fixed" className={classes.appBar}>
                <ToolBar hideShadow currentUser={currentUser} />
              </AppBar>
              <DrawerBar history={history} currentOrganization={currentOrganization} />
              <main className={classes.content}>
                <Switch>
                  <Route path="/activities" component={Activities} />
                  <Route path="/volunteers" component={Volunteers} />
                  <Route path="/opportunities" component={Opportunities} />
                  <Route path="/team/members" component={MyTeam} />
                  <Route path="/kiosk" component={Kiosk} />
                  <Redirect to={'/volunteers'} />
                </Switch>
              </main>
            </div>}
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ConnectedRouter>
  );
};

App.propTypes = {
  history: PropTypes.object
};

export default withAuthenticator(App, false, [
  <CustomSignIn />,
  <ForgotPassword />,
  <RequireNewPassword />
]);

import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TableToolbar from '../../components/table/TableToolbar';
import DataTable from '../../components/DataTable';
import { DateCell } from '../../components/table/cell';
import Loading from '../../components/Loading';
import AddressCard from 'components/AddressCard';
import LocationMap from 'components/LocationMap';
import useMiniSearch from 'utils/useMiniSearch';

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    padding: '3em 0'
  },
  root: {
    flexGrow: 1
  }
}));

const columns = [
  {
    Header: 'Name',
    accessor: 'name' // String-based value accessors!
  },
  {
    Header: 'Event Start',
    accessor: 'startsAt',
    Cell: DateCell 
  },
  {
    Header: 'Event End',
    accessor: 'endsAt',
    Cell: DateCell
  }
];

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
      processTerm: term => term.toLowerCase(),
      prefix: true
  }
}
function Kiosk(props) {
  const {
    getOpportunities,
    startKioskMode,
    opportunities,
    loading,
  } = props;

  const classes = useStyles();
  const [opportunity, setOpportunity] = useState(null);
  const isRowSelected = opportunity != null;

  const search = useMiniSearch(opportunities, searchConfig);
  const [query, setQuery] = useState('');
  const filteredOpportunities = search(query);

  useEffect(() => { getOpportunities() }, [getOpportunities]);

  const handleRowAction = useCallback( 
    row => setOpportunity(row.original),
    [setOpportunity]
  );

  const handleStartKiosk = useCallback( 
    () => startKioskMode(opportunity),
    [opportunity, startKioskMode]
  );

  const currentMarker = _.get(opportunity, 'geofencing.location.coordinates');

  return (
    <div className={classes.activityWrapper}>
      <Grid container direction="column">
        <Typography variant="h4" gutterBottom>
          Kiosk Mode
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Please choose the opportunity below to start your Kiosk Mode Session.
        </Typography>
      </Grid>
      {!loading || opportunities.length ? (
        <Grid container spacing={2} direction="row">
          <Grid item xs>
            <Paper>
              <TableToolbar
                query={query}
                onQueryChange={setQuery}
              />
              <DataTable
                columns={columns} 
                data={filteredOpportunities} 
                onRowAction={handleRowAction}
              />
            </Paper>
          </Grid>
          <Grid item xs>
            <AddressCard opportunity={opportunity} openDialogWindow={handleStartKiosk} isRowSelected={isRowSelected}>
              <Grid container>
                  <Grid item xs={12}>
                    <LocationMap currentMarker={currentMarker} />
                  </Grid>
              </Grid>
            </AddressCard>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Kiosk;

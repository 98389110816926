import _ from 'lodash';
import activitySources from '../enum/activitySources';
import { TIMESHEET } from '../enum/entryMethod';

export const getVolunteerName = activity => {
    const first = _.get(activity, 'user.givenName', '');
    const last = _.get(activity, 'user.familyName', '');
    return first + (first && last ? ' ' : '') + last;
}

export const getOpportunityName = activity => _.get(activity, 'opportunity.name');

export const isTimesheetActivity = activity => {
    if(!activity) return false;

    const { entryMethod } = activitySources[activity.occurredAtSource];
    return entryMethod == TIMESHEET; 
}
import React, { forwardRef } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    backButton: {
        color: 'grey'
    },
    submitButton: {
        background: '#FF6F3E',
        color: 'white'
    }
})

const credentialsSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required()
})

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function LeaveKioskDialog(props) {
    const { open, onSubmit, submitting, onCancel, error } = props;
    const classes = useStyles();

    const handleFormikSubmit = (values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false); 
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            disableBackdropClick={submitting}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Formik
                initialValues={{ username: '', password: '' }} 
                onSubmit={handleFormikSubmit}
                validationSchema={credentialsSchema}
            >{({
                values,
                errors,
                submitCount,
                handleChange,
                handleBlur,
                handleSubmit 
            }) => {
                const hasError = (field) => submitCount > 0 && !!errors[field];
                const getError = (field) => hasError(field) ? errors[field] : undefined;

                return (<form onSubmit={handleSubmit} noValidate>
                    <DialogTitle id="alert-dialog-title">Leave Kiosk Mode</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            An Administrator must login to exit kiosk mode.
                            {error && <Typography display="block" color="error">
                                {error}
                            </Typography>}
                        </DialogContentText>
                        <TextField
                            id="username"
                            name="username"
                            label="Username"
                            variant="outlined"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={hasError('username')}
                            helperText={getError('username')}
                            disabled={submitting}
                            margin="normal"
                            required
                            fullWidth
                            autoCapitalize="none"
                            autoCorrect="off"
                            spellCheck="false"
                            autoFocus />
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            type="password" 
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={hasError('password')}
                            helperText={getError('password')}
                            disabled={submitting}
                            margin="normal"
                            required
                            fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className={classes.backButton}
                            onClick={onCancel} 
                            disabled={submitting}
                        >
                            Back 
                        </Button>
                        <Button 
                            type="submit" 
                            className={classes.submitButton} 
                            disabled={submitting}
                            autoFocus
                        >
                            {submitting && <CircularProgress size={24} color="white" className={classes.buttonProgress}/>}
                            {'Login & Exit'} 
                        </Button>
                    </DialogActions>
                </form>)}}
            </Formik>
        </Dialog>
  );
}

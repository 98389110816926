import { fork, takeEvery, select, call, put } from 'redux-saga/effects';
import { API } from 'aws-amplify';

import { getOpportunity, getOpportunityId } from '../selectors';
import { 
    CHECK_IN, CHECK_OUT,
    checkInSucceeded, checkInFailed,
    checkOutSucceeded, checkOutFailed,
    GET_VOLUNTEERING_ROLES, KIOSK_STARTED,
    getVolunteeringRoles, getVolunteeringRolesSucceeded, getVolunteeringRolesFailed
} from '../actions'

export default function* rootSaga() {
    yield fork(watchCheckIn);
    yield fork(watchCheckOut);
    yield fork(watchGetVolunteeringRoles);
    yield fork(getRolesOnKioskStart);
}

export function* watchCheckIn() {
    yield takeEvery(CHECK_IN, onCheckIn);
}

export function* onCheckIn(action) {
    let { volunteer } = action.payload; 
    let opportunity = yield select(getOpportunity);
    try {
        //TODO check for errors!
        yield postToTimesheet({ action: 'check-in', volunteer, opportunity });
        yield put( checkInSucceeded(volunteer) );
    } catch (e) {
        yield put( checkInFailed(e) );
    }
}

export function* watchCheckOut() {
    yield takeEvery(CHECK_OUT, onCheckOut);
}

export function* onCheckOut(action) {
    let { volunteer, activityData } = action.payload; 
    let opportunity = yield select(getOpportunity);
    try {
        //TODO check for errors
        yield postToTimesheet({ 
            action: 'check-out', 
            volunteer, 
            opportunity, 
            data: activityData 
        });

        yield put( checkOutSucceeded(volunteer) );
    } catch (e) {
        yield put( checkOutFailed(e) );
    }
}

function postToTimesheet({ action, volunteer, opportunity, data={} }) {
    let config = {
        headers: {
        'X-Api-Version': '2019-06-30'
        },
        body: [{
            action: action,
            cognitoSub: volunteer.user.cognitoSub,
            opportunityId: opportunity.id,
            data
        }] 
    };

    return call([API, 'post'],'civicChampsApi', '/timesheet', config);
}

export function* getRolesOnKioskStart() {
    yield takeEvery(KIOSK_STARTED, function*() {
        yield put( getVolunteeringRoles() );
    })
}

export function* watchGetVolunteeringRoles() {
    yield takeEvery(GET_VOLUNTEERING_ROLES, onGetVolunteeringRoles);
}

export function* onGetVolunteeringRoles(getAction) {
    let oppId = yield select(getOpportunityId); //TODO this should be passed as an argument!
    let url = `/opportunities/${oppId}/roles`;
    try {
        let roles = yield call([API, 'get'], 'civicChampsApi', url);
        yield put( getVolunteeringRolesSucceeded(roles) );
    } catch(error) {
        yield put( getVolunteeringRolesFailed(error) );
    }
}
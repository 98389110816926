export const AUTH_INITIALIZED = 'auth.initialized';
export const SIGNED_IN = 'auth.signed-in';
export const SIGNED_OUT = 'auth.signed-out';

export const authInitialized = (user) => ({
    type: AUTH_INITIALIZED,
    payload: { user }
});

export const signedIn = (user) => ({
    type: SIGNED_IN,
    payload: { user }
});

export const signedOut = () => ({ type: SIGNED_OUT });
export const ACTIVITIES_REQUEST = 'ACTIVITIES_REQUEST';
export const ACTIVITIES_SUCCESS = 'ACTIVITIES_SUCCESS';
export const ACTIVITIES_FAILURE = 'ACTIVITIES_FAILURE';

export default {
  request: () => ({
    type: ACTIVITIES_REQUEST
  }),

  success: payload => ({
    type: ACTIVITIES_SUCCESS,
    payload
  }),
  failure: payload => ({
    type: ACTIVITIES_FAILURE,
    payload
  })
};

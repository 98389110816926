import { compose } from 'redux';
import { connect } from 'react-redux';
import Opportunities from './Opportunities';
import actions from '../../actions';
import { opportunitiesSelector } from './selectors';

const connector = () =>
  connect(
    state => ({
      ...opportunitiesSelector(state)
    }),
    dispatch => ({
      getOpportunities: data => dispatch(actions.opportunities.request(data)),
      filterOpportunities: data => dispatch(actions.opportunities.filter(data))
    })
  );

export default compose(connector())(Opportunities);

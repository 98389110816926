import { fork, takeEvery, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { Auth, Hub } from 'aws-amplify';

import { signedIn, signedOut, authInitialized } from './actions';

export default function* authRootSaga() {
    yield fork(watchForAuthEvents);
}

export function* watchForAuthEvents() {
    const channel = listenForAuthEvents();
    yield takeEvery(channel, onAuthEvent);
}

export function* onAuthEvent(authEvent) {
    yield put(authEvent);
}

function listenForAuthEvents() {
    return new eventChannel( emit => {
        const listener = data => {
            switch(data.payload.event) {
                case 'signIn':
                    emit( signedIn( mapHubEventToUser(data) ) );
                    break;

                case 'signOut':
                    emit( signedOut() );
                    break;

                default:
            }
        };

        getCurrentAuthenticatedUser()
            .then( user => {
                emit( authInitialized(user) );
                Hub.listen('auth', listener);
            })

        return () => Hub.remove('auth', listener);
    });
}

async function getCurrentAuthenticatedUser() {
    try {
        let userInfo = await Auth.currentAuthenticatedUser();
        return mapHubEventToUser({ payload: { data: userInfo } });
    } catch(error) {
        return null;
    }
}

function mapHubEventToUser(event) {
    let attrs = event.payload.data.attributes;

    let permissions = null;
    try {
        permissions = JSON.parse(attrs['custom:permissions']);
    } catch(error) {
        permissions = {
            role: 'USER',
            managedOrganization: null
        };
    }

    return {
        givenName: attrs.given_name,
        familyName: attrs.family_name,
        email: attrs.email,
        phone: attrs.phone || attrs.alt_phone,
        permissions
        //TODO map verification status
        //emailVerified: 
        //phoneVerified:
    }
}
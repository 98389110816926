import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import cn from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup'; 
import moment from 'moment';

import useStyles from './useStyles';

const formSchema = yup.object({
    givenName: yup.string().label('first name').required(),
    familyName: yup.string().label('last name').required(),
    email: yup.string().email(),
    phone: yup.string().matches(/\d{3}-?\d{3}-?\d{4}/, 'phone number is invalid'),
    birthday: yup.string()
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, '${path} must be DD/MM/YYYY') // eslint-disable-line no-template-curly-in-string
        .required()
})

export default function KioskSignUp({
        organizationName,
        signUpVolunteer,
        submitting,
        error,
        setSignUpError
}) {
    const classes = useStyles();

    const handleFormikSubmit = (values, { setSubmitting }) => {
        let email = !_.isEmpty(values.email) ? values.email : null;
        let phoneNumber = !_.isEmpty(values.phoneNumber)
            ? '+1' + (values.phoneNumber || '').replace(/[^\d]/g, '')
            : null;

        //TODO this is a hack!
        if(email == null && phoneNumber == null) {
            setSignUpError('At least one form of contact (email or phone) is required');
            setSubmitting(false);
            return;
        }

        let payload = {
            givenName: values.givenName,
            familyName: values.familyName,
            email,
            phoneNumber,
            birthday: moment(values.birthday || '', 'MM/DD/YYYY').format('YYYY-MM-DD')
        }

        signUpVolunteer(payload);
        setSubmitting(false);
    }
    return (<>
        <Grid container justify="flex-end">
            <Link 
                to="/kiosk"
                className={classes.actionButton}
            >
                Check in
            </Link>
        </Grid>
        <Container maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
            <Typography variant="h4" gutterBottom>
                {organizationName}
            </Typography>
            {error && <Typography display="block" color="error">{error}</Typography>}
            <Formik
                initialValues={{
                    givenName: '',
                    familyName: '',
                    email: '',
                    phoneNumber: '',
                    birthday: ''
                }}
                validationSchema={formSchema}
                onSubmit={handleFormikSubmit}
            >
            {({
                values,
                submitCount,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => {
                const getError = (field) => submitCount > 0 && !!errors[field];
                const getErrorText = (field) => getError(field) ? errors[field] : undefined;

                return (<form onSubmit={handleSubmit} className={classes.form} noValidate>
                    <Container maxWidth="md" className={classes.formWrapper}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="firstName"
                                name="givenName"
                                label="First Name"
                                variant="outlined"
                                value={values.givenName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError('givenName')}
                                helperText={getErrorText('givenName')}
                                disabled={submitting}
                                autoComplete="fname"
                                required
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="lastName"
                                name="familyName"
                                label="Last Name"
                                variant="outlined"
                                value={values.familyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError('familyName')}
                                helperText={getErrorText('familyName')}
                                disabled={submitting}
                                autoComplete="lname"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError('email')}
                                helperText={getErrorText('email')}
                                disabled={submitting}
                                autoComplete="email"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="phone" 
                                name="phoneNumber" 
                                label="Phone" 
                                variant="outlined" 
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError('phoneNumber')}
                                helperText={getErrorText('phoneNumber')}
                                disabled={submitting}
                                fullWidth 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="Birthday" 
                                name="birthday" 
                                label="Birthday" 
                                variant="outlined" 
                                placeholder={"MM/DD/YYYY"}
                                value={values.birthday}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError('birthday')}
                                helperText={getErrorText('birthday')}
                                disabled={submitting}
                                required 
                                fullWidth 
                            />
                        </Grid>
                        </Grid>
                    </Container>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={submitting}
                        className={cn(classes.checkIn, classes.checkInColor)}
                    >
                        {submitting && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        Check in
                    </Button>
                </form>)}}
            </Formik>
        </div>
        </Container>
    </>)
}
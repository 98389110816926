export const VOLUNTEERS_REQUEST = 'VOLUNTEERS_REQUEST';
export const VOLUNTEERS_SUCCESS = 'VOLUNTEERS_SUCCESS';
export const VOLUNTEERS_FAILURE = 'VOLUNTEERS_FAILURE';

export default {
  request: () => ({
    type: VOLUNTEERS_REQUEST
  }),

  success: payload => ({
    type: VOLUNTEERS_SUCCESS,
    payload
  }),
  failure: payload => ({
    type: VOLUNTEERS_FAILURE,
    payload
  })
};

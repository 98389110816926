import _ from 'lodash';
import { fork, select, take, call, put, takeEvery } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';

import parseUsername from 'auth/utils/parseUsername';
import { 
    START_KIOSK, 
    STOP_KIOSK, 
    START_KIOSK_CLEAR_FAILURE,
    startKioskSucceeded, 
    startKioskFailed,
    kioskStarted,
    stopKioskSucceeded,
    stopKioskFailed,
    kioskStopped
} from '../actions';
import { isKioskMode } from '../selectors';
import { requestSetFullscreen } from '../fullscreen';
import warnOnUnload from '../warnOnUnload';

export default function* kioskModeSaga() {
    yield fork(kioskModeFlow);
    yield fork(watchClearFailure);
}

export function* kioskModeFlow() {
    let running = yield select(isKioskMode);
    while(true) {
        while(!running) {
            const startAction = yield take(START_KIOSK);
            running = yield call(tryStartKiosk, startAction);
        }

        yield put( kioskStarted() );
 
        while(running) {
            const stopAction = yield take(STOP_KIOSK);
            running = yield call(tryStopKiosk, stopAction);
        }

        yield put( kioskStopped() );
    }
}

export function* tryStartKiosk(startAction) {
    try {
        warnOnUnload.start();
        yield put( requestSetFullscreen(true) ); //TODO must be in response to user input
        yield call(loginKioskUser, startAction);
        yield put ( startKioskSucceeded() );
        return true;
    } catch(e) {
        warnOnUnload.stop();
        yield put( startKioskFailed(e) );
        return false;
    }
}

export function* tryStopKiosk(stopAction) {
    try {
        yield call(logoutKioskUser, stopAction);
        yield put( requestSetFullscreen(false) );
        warnOnUnload.stop();
        yield put( stopKioskSucceeded() );
        return false;
    } catch(e) {
        yield put( stopKioskFailed(e) );
        return true;
    }
}

export function* loginKioskUser(startEvent) {
    const id = _.get(startEvent, 'payload.opportunity.organization.id');
    const url = `/organizations/${id}/kiosk`;
    const { username, password } = yield call([API, 'post'], 'civicChampsApi', url);
    yield call([Auth, 'signIn'], username, password);
} 

export function* logoutKioskUser(endEvent) {
    const { username, password } = _.get(endEvent, 'payload.adminCredentials');
    yield call([Auth, 'signIn'], parseUsername(username), password);
}

export function* watchClearFailure() {
    yield takeEvery(START_KIOSK_CLEAR_FAILURE, function*() {
        yield put( requestSetFullscreen(false) );
    })
}

import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker /*, StreetViewPanorama, InfoWindow*/ } from 'react-google-maps';

const API_KEY = 'AIzaSyD9HN9PRW0rUjp2qR58lKQBGgDF7tyxcEM'

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ markerData = [], onMarkerClick }) => (
  <GoogleMap defaultOptions={{disableDefaultUI:true}} defaultZoom={14} center={{ lat: markerData[1] || 39.172629, lng: markerData[0] || -86.536931 }}>
    <Marker position={{ lat: markerData[1], lng: markerData[0] }} onClick={onMarkerClick}/>
  </GoogleMap>
));

class MyFancyComponent extends React.PureComponent {
  state = {
    isMarkerShown: false
  };

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false });
  };

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
        markerData={this.props.currentMarker}
      />
    );
  }
}

export default MyFancyComponent;

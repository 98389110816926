import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import migrate from './migrate';
import reducer from '../reducers';
import root from '../sagas';

export const history = createBrowserHistory();
const saga = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [ 'router', 'auth', 'api' ], //temporarily blacklist 'api'
  migrate
}

const persistedReducer = persistReducer(persistConfig, reducer(history));

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(saga), 
    applyMiddleware(routerMiddleware(history))
  )
);

saga.run(root);

export const persistor = persistStore(store);
export default store;

import { VOLUNTEERS_REQUEST, VOLUNTEERS_SUCCESS } from '../actions/volunteers';

export const defaultState = {
  volunteers: [],
  loading: false
};

export default function config(state = defaultState, action) {
  switch (action.type) {
    case VOLUNTEERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VOLUNTEERS_SUCCESS:
      return {
        ...state,
        volunteers: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

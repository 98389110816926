import { compose } from 'redux';
import { connect } from 'react-redux';
import Volunteers from './Volunteers';
import actions from '../../actions';
import { volunteersSelector } from './selectors';

const connector = () =>
  connect(
    state => ({
      ...volunteersSelector(state)
    }),
    dispatch => ({
      getVolunteers: () => dispatch(actions.volunteers.request())
    })
  );

export default compose(connector())(Volunteers);

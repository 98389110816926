import { fork, takeEvery, take, race, delay, select, all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { API } from 'aws-amplify'

import { getOrganizationId, getOpportunityId } from '../selectors';
import { 
    SIGN_UP_VOLUNTEER, signUpVolunteerSucceeded, signUpVolunteerFailed, 
    GET_VOLUNTEERS, getVolunteers, getVolunteersSucceeded, getVolunteersFailed,
    checkIn,
    KIOSK_STARTED, KIOSK_STOPPED
} from '../actions';
import processRawVolunteers from './utils/processRawVolunteers';

export default function* volunteersSaga() {
    yield fork(watchGetVolunteers);
    yield fork(watchSignUpVolunteer);
    yield fork(synchronizeVolunteersFlow);
}

export function* synchronizeVolunteersFlow() {
    while(true) {
        yield take(KIOSK_STARTED);
        yield race([
            take(KIOSK_STOPPED),
            call(function*() {
                while(true) {
                    yield put( getVolunteers() );
                    yield delay( 1000 * 60 * 5 );
                }
            })
        ])
    }
}

export function* watchGetVolunteers() {
    yield takeEvery(GET_VOLUNTEERS, onGetVolunteers);
}

export function* onGetVolunteers() {
    try {
        let [allVolunteers, activeVolunteers] = yield all([ fetchVolunteers(), fetchActiveVolunteers() ]); 
        let volunteers = processRawVolunteers(allVolunteers, activeVolunteers)
        
        yield put( getVolunteersSucceeded(volunteers) );
    } catch(error) {
        yield put( getVolunteersFailed(error) );
    }
}

function* fetchVolunteers() {
    let orgId = yield select(getOrganizationId);
    let url = `/organizations/${orgId}/volunteers`;
    return yield call([API, 'get'], 'civicChampsApi', url);
}

function* fetchActiveVolunteers() {
    let oppId = yield select(getOpportunityId);
    let url = `/opportunities/${oppId}/active_volunteers`
    return yield call([API, 'get'], 'civicChampsApi', url);
}

export function* watchSignUpVolunteer() {
    yield takeEvery(SIGN_UP_VOLUNTEER, onSignUpVolunteer);
}

export function* onSignUpVolunteer(action) {
    try {
        let { givenName, familyName, phoneNumber, email } = action.payload.signUpData;
        let orgId = yield select(getOrganizationId);
        let url = `/organizations/${orgId}/volunteers`;
        let config = {
            body: {
                givenName,
                familyName,
                email,
                phoneNumber                
            } 
        }

        let newVolunteer = yield call([API, 'post'], 'civicChampsApi', url, config);
        yield put( signUpVolunteerSucceeded(newVolunteer) );
        yield put( checkIn(newVolunteer) );
        yield put( push('/kiosk') );
    } catch(error) {
        yield put( signUpVolunteerFailed(error) )
    }
}

import { connect } from 'react-redux';

import KioskCheckIn from './KioskCheckIn';
import { organizationName, getTimesheetMode, getVolunteeringRoles, getTimesheetList } from '../selectors';
import { setTimesheetMode, checkIn, checkOut } from '../actions';

const mapStateToProps = state => ({
    organizationName: organizationName(state),
    timesheetMode: getTimesheetMode(state),
    volunteeringRoles: getVolunteeringRoles(state),
    volunteers: getTimesheetList(state)
});

const mapDispatchToProps = dispatch => ({
    setTimesheetMode: (mode) => dispatch( setTimesheetMode(mode) ),
    checkIn: (volunteer) => dispatch( checkIn(volunteer) ),
    checkOut: (volunteer, activityData) => dispatch( checkOut(volunteer, activityData) )
});

export default connect(mapStateToProps, mapDispatchToProps)(KioskCheckIn);
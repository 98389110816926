import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import opportunities from './opportunities';
import volunteers from './volunteers';
import givingOrgs from './givingOrgs';

import auth from '../auth/reducer';
import kiosk from '../kiosk/reducer';
import fullscreen from '../kiosk/fullscreen/reducer';
import notifications from '../notifications/reducer';
//import tracking from '../tracking/reducer';
import api from '../api/reducer';

import team from '../members/team/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    opportunities,
    volunteers,
    kiosk,
    fullscreen,
    notifications,
    team,
    api,
    givingOrgs
  });

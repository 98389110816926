import { connect } from 'react-redux';
import App from './App';

import { currentOrganization, currentUser } from '../auth/selectors';

const mapStateToProps = state => ({
    isKioskMode: state.kiosk.status !== 'inactive',
    currentOrganization: currentOrganization(state),
    currentUser: currentUser(state)
});

export default connect(mapStateToProps)(App);
import { isNumber, size } from 'lodash'
import humanFormat from 'human-format'
import numbers from 'react-number-format'
import moment from 'moment'

const toFixed = (value, precision) => {
    let number = isNumber(value) ? value : humanFormat(value,{decimals: 1});
    return number.toFixed(precision);
}

const toFormattedDate = (value, format) => {
    if (value != null) {
        let date = moment(value);
        
        return date.isValid() ? date.format(format) : null;
    }

    return null;
}

const formatCount = (collection) => humanFormat(size(collection), { decimals: 1 });

/*const formatNumber = (total) => format(total || 0, { decimals: 1 });*/

const formatHours = (hours) => hours > 100000 ? humanFormat(hours || 0, { decimals: 1 }) : Intl.NumberFormat('en',{style:'decimal', maximumFractionDigits: 0}).format(hours || 0);

const formatDate = (date) => toFormattedDate(date, 'MM/DD/YYYY');

const formatDateTime = (dateTime) => toFormattedDate(dateTime, 'MM/DD/YYYY hh:mm a');

const formatNumber = (total) => total > 100000 ? humanFormat(total || 0, { decimals: 1 }) : Intl.NumberFormat('en',{style:'decimal', maximumFractionDigits: 0}).format(total || 0);

const formatCurrency = (amount) => amount > 100000 ? '$'+humanFormat(amount || 0, { decimals: 1 }) : '$'+Intl.NumberFormat('en',{style:'decimal', maximumFractionDigits: 0}).format(amount || 0);


export default {
    count: formatCount,
    number: formatNumber,
    hours: formatHours,
    date: formatDate,
    datetime: formatDateTime,
    fixedPoint: toFixed,
    currency: formatCurrency
}

export const GET_VOLUNTEERS = 'kiosk.get-volunteers';
export const GET_VOLUNTEERS_SUCCEEDED = 'kiosk.get-volunteers.succeeded';
export const GET_VOLUNTEERS_FAILED = 'kiosk.get-volunteers.failed';
export const SIGN_UP_VOLUNTEER = 'kiosk.sign-up-volunteer';
export const SIGN_UP_VOLUNTEER_SUCCEEDED = 'kiosk.sign-up-volunteer.succeeded';
export const SIGN_UP_VOLUNTEER_FAILED = 'kiosk.sign-up-volunteer.failed';

export const getVolunteers = () => ({ type: GET_VOLUNTEERS })

export const getVolunteersSucceeded = (volunteers) => ({
    type: GET_VOLUNTEERS_SUCCEEDED,
    payload: { volunteers }
});

export const getVolunteersFailed = (error) => ({
    type: GET_VOLUNTEERS_FAILED,
    payload: { error }
});

export const signUpVolunteer = (signUpData) => ({
    type: SIGN_UP_VOLUNTEER,
    payload: { signUpData }
});

export const signUpVolunteerSucceeded = (newVolunteer) => ({
    type: SIGN_UP_VOLUNTEER_SUCCEEDED,
    payload: { newVolunteer }
});

export const signUpVolunteerFailed = (error) => ({
    type: SIGN_UP_VOLUNTEER_FAILED,
    payload: { error }
});
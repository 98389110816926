export const OPPORTUNITIES_REQUEST = 'OPPORTUNITIES_REQUEST';
export const OPPORTUNITIES_SUCCESS = 'OPPORTUNITIES_SUCCESS';
export const OPPORTUNITIES_FAILURE = 'OPPORTUNITIES_FAILURE';
export const OPPORTUNITIES_BY_DATE_RANGE_REQUEST = 'OPPORTUNITIES_BY_DATE_RANGE_REQUEST';
export const OPPORTUNITIES_BY_DATE_RANGE_SUCCESS = 'OPPORTUNITIES_BY_DATE_RANGE_SUCCESS';
export const VOLUNTEERS_BY_OPPORTUNITIES_REQUEST = 'VOLUNTEERS_BY_OPPORTUNITIES_REQUEST';
export const VOLUNTEERS_BY_OPPORTUNITIES_SUCCESS = 'VOLUNTEERS_BY_OPPORTUNITIES_SUCCESS';
export const OPPORTUNITIES_FILTER = 'OPPORTUNITIES_FILTER';
export const OPPORTUNITIES_FILTER_SUCCESS = 'OPPORTUNITIES_FILTER_SUCCESS';

export default {
  request: payload => ({
    type: OPPORTUNITIES_REQUEST,
    payload
  }),

  success: payload => ({
    type: OPPORTUNITIES_SUCCESS,
    payload
  }),

  opportunitiesByDateRangeRequest: payload => ({
    type: OPPORTUNITIES_BY_DATE_RANGE_REQUEST,
    payload
  }),

  opportunitiesByDateRangeSuccess: payload => ({
    type: OPPORTUNITIES_BY_DATE_RANGE_SUCCESS,
    payload
  }),

  volunteersByOpportunityRequest: payload => ({
    type: VOLUNTEERS_BY_OPPORTUNITIES_REQUEST,
    payload
  }),

  volunteersByOpportunitySuccess: payload => ({
    type: VOLUNTEERS_BY_OPPORTUNITIES_SUCCESS,
    payload
  }),
  failure: payload => ({
    type: OPPORTUNITIES_FAILURE,
    payload
  }),
  filter: payload => ({
    type: OPPORTUNITIES_FILTER,
    payload
  }),
  filterSuccess: payload => ({
    type: OPPORTUNITIES_FILTER_SUCCESS,
    payload
  })
};

import { SIGNED_IN, SIGNED_OUT, AUTH_INITIALIZED } from './actions';

const defaultState = { 
    initialized: false,
    currentUser: null 
};

export default function(state=defaultState, event) {
    switch(event.type) {
        case AUTH_INITIALIZED:
            return { 
                ...state,
                initialized: true,
                currentUser: event.payload.user
            };

        case SIGNED_IN: 
            return { 
                ...state,
                currentUser: event.payload.user
            };
        
        case SIGNED_OUT: 
            return { ...state, currentUser: null }

        default:
            return state;
    }
}
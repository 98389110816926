import { fork, takeEvery, put } from 'redux-saga/effects';

import { 
    CHECK_IN_SUCCEEDED, CHECK_IN_FAILED,
    CHECK_OUT_SUCCEEDED, CHECK_OUT_FAILED,
    GET_VOLUNTEERING_ROLES_FAILED,
    getVolunteeringRoles
} from 'kiosk/actions';
import { getFullName } from './utils';
import { enqueueNotification } from 'notifications/actions';

//TODO maybe power this off of a config property and move to <Notifications /> ?
import React from 'react';
import RetryFetchButton from '../../components/RetryFetchButton'

export default function*() {
    yield fork(watchTimesheetNotifications)
    yield fork(watchGetVolunteeringRolesFailed)
}

function* watchTimesheetNotifications() {
    yield takeEvery([CHECK_IN_SUCCEEDED, CHECK_OUT_SUCCEEDED], onTimesheetSuccess);
    yield takeEvery([CHECK_IN_FAILED, CHECK_OUT_FAILED], onTimesheetFailure);
}

function* onTimesheetSuccess(action) {
    const type = action.type === CHECK_IN_SUCCEEDED ? 'checked in' : 'checked out';
    const name = getFullName(action.payload.volunteer);
    const message = `${name} successfully ${type}!`;
    const options = {
        variant: 'success',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
        }
    }

    yield put( enqueueNotification(message, options) );
}

function* onTimesheetFailure(action) {
    const type = action.type === CHECK_IN_FAILED ? 'Check in' : 'Check out';
    const message = `${type} failed.  Please try again later`;
    const options = {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
        }
    }

    yield put( enqueueNotification(message, options));
}

function* watchGetVolunteeringRolesFailed() {
    yield takeEvery(GET_VOLUNTEERING_ROLES_FAILED, onGetRolesFailure);
}

function* onGetRolesFailure() {
    const msg = 'Failed to load activities';
    const options = {
        variant: 'error',
        action: (close) => (<RetryFetchButton action={getVolunteeringRoles} close={close} />),
        persist: true
    }
    yield put( enqueueNotification(msg, options) )
}
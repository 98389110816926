import { get, sumBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Card from '../../components/Card';
import ObjectTable from '../../components/table/ObjectTable';
import Loading from '../../components/Loading';
import { IntegerCell, DateCell, FixedPointCell } from 'components/table/cell'
import useMiniSearch from 'utils/useMiniSearch';
import format from 'utils/format';

const columns = [
  { Header: 'First Name', accessor: 'user.givenName' },
  { Header: 'Last Name', accessor: 'user.familyName' },
  { Header: 'Email', accessor: 'user.email' },
  /*{
    Header: 'Phone',
    accessor: 'user.phoneNumber',
    Cell: props => <div style={{ textAlign: 'right', marginRight: '10px' }}>{props.value}</div>
  },*/
  {
    Header: 'Hours', 
    accessor: 'volunteering.totalVolunteerHours',
    Cell: FixedPointCell
  },
  {
    Header: 'Check-Ins', 
    accessor: 'volunteering.totalCheckIns',
    Cell: IntegerCell
  },
  {
    Header: 'Last Activity', // Custom header components!
    accessor: 'volunteering.latestActivity',
    Cell: DateCell
  }
];

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    padding: '3em 0'
  }
}));

const headers = [
  {
    label: 'Last Name',
    key: 'user.givenName' // String-based value accessors!
  },
  {
    label: 'Last Name',
    key: 'user.familyName' // String-based value accessors!
  },
  {
    label: 'Email',
    key: 'user.email'
  },
  /*{
    label: 'Phone',
    key: 'user.phoneNumber'
  },*/
  {
    label: 'Hours',
    key: 'volunteering.totalVolunteerHours'
  },
  {
    label: 'Check-Ins', // Custom header components!
    key: 'volunteering.totalCheckIns'
  },
  {
    label: 'Last Activity', // Custom header components!
    key: 'volunteering.latestActivity'
  }
];

const searchConfig = {
  fields: ['user.givenName', 'user.familyName', 'user.email', 'volunteeringRole.name'],
  extractField: get,
  processTerm: (term)  => term.toLowerCase(),
  searchOptions: {
      processTerm: term => term.toLowerCase(),
      prefix: true
  }
}

function Volunteers(props) {
  const { getVolunteers, volunteers = [], loading } = props;
  const classes = useStyles();

  const search = useMiniSearch(volunteers, searchConfig);

  const volunteersCards = useMemo( () => {
      const nationalAvgVolValue = 25.43;
      const volunteersHours = sumBy(volunteers, v => v.volunteering.totalVolunteerHours || 0);
      return [
        {
          header: 'Active Volunteers',
          body: `${format.count(volunteers)}`,
          toolTipText:''

        },
        {
          header: 'Volunteers Hours',
          body: `${format.number(volunteersHours)}`,
          toolTipText:''
        },
        {
          header: 'Estimated Impact',
          body: `${format.currency(volunteersHours*nationalAvgVolValue)}`,
          toolTipText:'This value was calculated using the national average of $'+nationalAvgVolValue+' / hour for volunteers.'
        }
      ];
  }, [volunteers]);

  useEffect(() => { getVolunteers() }, [getVolunteers]);

  return (
    <div className={classes.activityWrapper}>
      <Grid container>
        <Typography gutterBottom variant="h4">
          Volunteers
        </Typography>
      </Grid>
      {!loading || volunteers.length ? (
        <>
          <Hidden only="sm">
            <Grid container item justify="center" spacing={6} className={classes.cardsWrapper}>
              {volunteersCards.map((item, index) => (
                <Card key={index} data={item} />
              ))}
            </Grid>
          </Hidden>
          <ObjectTable  
            columns={columns} 
            data={volunteers} 
            search={search}
            csvHeaders={headers}
            exportFilename="volunteers.csv"
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Volunteers;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import cn from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import { get } from 'lodash';
import Icon from '@mdi/react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { mdiAccountGroup, mdiCharity, mdiViewDashboard, mdiAccountMultiple, mdiAccount, mdiCalendarStar, mdiSettings, mdiMonitorStar, mdiTicket, mdiAccountKey } from '@mdi/js';

const drawerWidth = 245;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#FFCD00'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#5b6e9e'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  menuButton: {
    zIndex: 1101,
    top: -60,
    left: 15,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: { ...theme.mixins.toolbar, minHeight: 66 },
  listItem: {
    padding: 10,
    color: 'white',
    backgroundColor: '#7D98DA',
    margin: '0 0 10px',
    minHeight: 52,
    maxHeight: 52
  },
  icons: {
    color: 'white',
    minWidth: 35
  },
  iconBlock: {
    marginRight: 10,
    borderRight: '10px solid transparent',
    display: 'block',
    width: '1px',
    height: '45px'
  },
  iconsSelected: {
    color: 'white',
    borderRight: '10px solid',
    display: 'block',
    width: '1px',
    height: '45px'
  },
  selected: {
    backgroundColor: '#7c97d8'
  },
  orgNameWrapper: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    height: '69px'
  },

}));

const DrawerBar = ({ history, currentOrganization, container }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(history.location.pathname || '/volunteers');
  const [mobileOpen, setMobileOpen] = useState(false);

  function handleListItemClick(index, { route, label }) {
    setSelectedIndex(route);
    setMobileOpen(false);
    history.push(route);
  }
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); }
  const orgName = get(currentOrganization, 'name');

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Typography variant="h6" noWrap className={classes.orgNameWrapper}>
          {orgName}
        </Typography>
      </div>
      <Divider />
      <List>
        {[
/*          { label: 'Dashboard', route: '/dashboard', icon: <Icon path={mdiViewDashboard} size={1} color="white"/> }, */
          { label: 'My Team', route: '/team/members', icon: <Icon path={mdiAccountGroup} size={1} color="white"/> },
/*          { label: 'Beneficiaries', route: '/givingOrganisation', icon: <Icon path={mdiCharity} size={1} color="white"/> }, */
          { label: 'Volunteers', route: '/volunteers', icon: <Icon path={mdiAccount} size={1} color="white"/> },
          { label: 'Opportunities', route: '/opportunities', icon: <Icon path={mdiCalendarStar} size={1} color="white"/> },
          { label: 'Activities', route: '/activities', icon: <Icon path={mdiTicket} size={1} color="white"/> },
/*           { label: 'Groups', route: '/groups', icon: <Icon path={mdiAccountMultiple} size={1} color="white"/> },*/
          { label: 'Kiosk', route: '/kiosk', icon: <Icon path={mdiMonitorStar} size={1} color="white"/> }
/*           { label: 'Settings', route: '/settings', icon: <Icon path={mdiSettings} size={1} color="white"/> },*/
/*           { label: 'Admin', route: '/admin', icon: <Icon path={mdiAccountKey} size={1} color="white"/> }*/

        ].map((item, index) => (
          <ListItem
            button
            key={index}
            className={cn(classes.listItem)}
            selected={selectedIndex === item.route}
            classes={{ selected: classes.selected }}
            onClick={event => handleListItemClick(index, item)}
          >
            {<div className={cn(classes.iconBlock, selectedIndex === item.route && classes.iconsSelected)} />}
            <ListItemIcon className={classes.icons}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <nav>
      <div className={classes.menuButton}>
      <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
      </Hidden>
    </nav>
  );
};

Drawer.propTypes = {
  history: PropTypes.object
};

export default DrawerBar;

import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import store, { history, persistor } from './store';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import ToolBar from './components/ToolBar';
import './config-amplify';
import './index.css';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans'].join(','),
    useNextVariants: true
  },
  palette: {
    primary: { main: '#FFCD00' },
    secondary: { main: '#5B6E9E' },
    background: {}
  }
});

const muiInternalTheme = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans'].join(','),
    useNextVariants: true
  },
  palette: {
    primary: { main: '#BFDF57' },
    secondary: { main: '#3D3D3D' },
    background: {}
  }
});

render(
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ToolBar hideLogout />
          <App history={history} />
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instantiateQueryConfig from '../utils/instantiateQueryConfig';
import { getQueryState } from '../selectors';
import { runQuery } from '../actions';

export default function useQuery(queryDef, params) {
    const dispatch = useDispatch();

    const config = useMemo( 
        () => instantiateQueryConfig(queryDef, params), 
        [queryDef, params]
    );

    const selector = useMemo(() => getQueryState(config), [config]);
    const queryState = useSelector(selector);

    const refresh = useCallback( 
        () => { dispatch( runQuery(config) ) }, //TODO make this force past caching!
        [dispatch, config]
    ); 

    useEffect(() => { refresh(false) }, [refresh]);
    
    return useMemo(
        () => ({ ...queryState, refresh }),
        [queryState, refresh]
    );
}
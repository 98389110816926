import React from 'react';
import { Auth } from 'aws-amplify';
import cn from 'classnames';
import DarkGrayHand from '../images/DarkGrayHand.png';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { Menu, MenuItem, IconButton, Toolbar, Typography, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: '#FFCD00',
  },
  hideShadow: {
    '& header': {
      boxShadow: 'none',
    }
  },
  menu: {
    fontSize: 15,
    fontWeight: 300,
    fontFamily:'Open Sans'
  },
  menuButton: {
    fontSize: 15,
    fontFamily:'Open Sans',
    fontWeight: 300,
    color: '#000000',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  title: {
    marginLeft: 10,
    flexGrow: 1,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 30,
    }
  },
}));


export default function ButtonAppBar({ hideShadow, currentUser }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [internalMode, setInternalMode] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const openMenu = (event) => { setAnchorEl(event.currentTarget); };
  const closeMenu = () => { setAnchorEl(null); };
  const logOut = () => { Auth.signOut(); closeMenu(); };

  const toggleInternalMode = () => {
    console.log(`INTERNAL MODE = ${!internalMode}`);
    setInternalMode(!internalMode);
    closeMenu();
  };

  const isInternalAdmin = currentUser && currentUser.permissions.role === 'INTERNAL_ADMIN';
  const internalAdminOption = isInternalAdmin
    ? <MenuItem onClick={toggleInternalMode}>{internalMode ? 'Normal Mode' : 'Internal Admin'}</MenuItem>
    : '';

  const userMenu = currentUser
   ? <div>
        <IconButton className={classes.menuButton}
          onClick={openMenu}>
          {`${currentUser.givenName} ${currentUser.familyName}`}
          <ExpandMore />
        </IconButton>
        <Menu className={classes.menu}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          transitionDuration={0}
          keepMounted
          open={isMenuOpen}
          onClose={closeMenu}>
          <MenuItem disabled={true}>Profile</MenuItem>
          {internalAdminOption}
          <MenuItem onClick={logOut}>Logout</MenuItem>
        </Menu>
      </div>
    : '';

  return (
    <div className={cn(classes.root, hideShadow ? classes.hideShadow : '')}>
      <Toolbar className={cn(classes.root)}>
        <img src={DarkGrayHand} alt="" className={classes.logo} />
        <Typography variant="h6" className={classes.title}>
          {hideShadow ? '' : 'Civic Champs Admin'}
        </Typography>
        {userMenu}
      </Toolbar>
    </div>
  );
}

import { get, meanBy, sumBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';

import ObjectTable from 'components/table/ObjectTable';
import { DateCell, HoursCell } from 'components/table/cell';
import Loading from 'components/Loading';
import Card from 'components/Card';
import useStyles from './useStyles';
import format from 'utils/format';
import useMiniSearch from 'utils/useMiniSearch';

const columns = [
    {
        Header: 'First Name',
        accessor: 'user.givenName',
    },
    {
        Header: 'Last Name',
        accessor: 'user.familyName'
    },
    {
        Header: 'Email',
        accessor: 'user.email'
    },
    {
        Header: 'Joined Date',
        accessor: 'membership.startedAt',
        Cell: DateCell
    },
    {
        Header: 'Hours',
        accessor: 'membership.totalVolunteerHours',
        Cell: HoursCell
    },
    {
        Header: 'Last Activity',
        accessor: 'membership.latestActivityAt',
        Cell: DateCell
    }
];

const headers = [
  { label: 'First Name', key: 'user.givenName' },
  { label: 'Last Name', key: 'user.familyName' },
  { label: 'Email', key: 'user.email' },
  { label: 'Joined Date', key: 'membership.startedAt' },
  { label: 'Hours', key: 'membership.totalVolunteerHours' },
  { label: 'Last Activity', key: 'membership.latestActivityAt' }
];

const searchConfig = {
    fields: ['user.givenName', 'user.familyName', 'user.email'],
    extractField: get,
    processTerm: (term, field) => term.toLowerCase(),
    searchOptions: {
        processTerm: term => term.toLowerCase(),
        prefix: true
    }
}

function MyTeam(props) {
    const { getTeamMembers, members=[], loading } = props;

    const classes = useStyles();
    const search = useMiniSearch(members, searchConfig);

    useEffect( () => { getTeamMembers() }, [getTeamMembers] );

    const myTeamCards = useMemo( () => {
        const totalVolunteerHours = sumBy(members, 'membership.totalVolunteerHours') || 0;
        const averageHours = meanBy(members, 'membership.totalVolunteerHours') || 0;

        return [{
            header: 'Members',
            body: `${format.count(members)}`
        }, {
            header: 'Hours',
            body: `${format.hours(totalVolunteerHours)}`
        }, {
            header: 'Hours Per Member',
            body: `${format.hours(averageHours)}`
        }];
    }, [members]);

    return (
        <div className={classes.activityWrapper}>
            <Grid container>
                <Typography gutterBottom variant="h4">
                    My Team
                </Typography>
            </Grid>
            {!loading ? (<>
                <div className={classes.root}>
                    <Hidden only="sm">
                        <Grid container justify="center" spacing={4} className={classes.cardsWrapper}>
                            {myTeamCards.map((item, index) => (
                                <Card key={index} data={item} />
                            ))}
                        </Grid>
                    </Hidden>
                </div>
                <ObjectTable
                    columns={columns} 
                    data={members} 
                    search={search}
                    csvHeaders={headers}
                    exportFilename="members.csv"
                />
            </>) : (
                <Loading />
            )}
        </div>
    );
}

export default MyTeam;

export const GET_TEAM_MEMBERS = 'team.get-members';
export const GET_TEAM_MEMBERS_SUCCEEDED = 'team.get-members.succeeded';
export const GET_TEAM_MEMBERS_FAILED = 'team.get-members.failed';

export const getTeamMembers = () => ({ type: GET_TEAM_MEMBERS });

export const getTeamMembersSucceeded = (members) => ({
    type: GET_TEAM_MEMBERS_SUCCEEDED,
    payload: { members }
});

export const getTeamMembersFailed = (error) => ({
    type: GET_TEAM_MEMBERS_FAILED,
    payload: { error }
});
import React from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Paper, Grid, Typography, Tooltip } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    height: 115,
    color: '#707070',
    fontFamily:'Open Sans',
    borderRadius:'10px',
    boxShadow:'0px 3px 6px 0px rgba(0,0,0,0.2)'
  },
  cardTitle:{
    fontFamily:'Open Sans'
  },

  icon: {
    padding: '15px 0',
    '& svg': {
      height: '27px',
      width: '27px'
    }
  },
  cardBody: {
    paddingTop: '15px',
    whiteSpace: 'nowrap',
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#3d3d3d',
    color: '#ffffff',
    maxWidth: 220,
    fontSize: '1em',
    border: '1px solid #dadde9',
  },
}))(Tooltip);


export default function MediaCard({ data }) {
  const classes = useStyles();
  const header = get(data, 'header');
  const body = get(data, 'body');
  const toolTipText = get(data, 'toolTipText');

  return (
    <Grid item xs={4}>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item container justify="center" align="center">
            <Typography className="cardTitle" color="textSecondary" component="p">
              {header}
            </Typography>
          </Grid>
          <Grid item container justify="center" align="center">
            <HtmlTooltip title={toolTipText == null ? "" : toolTipText} className={classes.cardToolTip}>
              <Typography variant="h4" component="h4" className={classes.cardBody}>
                {body}
              </Typography>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

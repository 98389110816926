import { fork } from 'redux-saga/effects'

import kioskModeSaga from './kioskMode';
import volunteersSaga from './volunteers'
import timesheetSaga from './timesheet';
import notificationsSaga from './notifications';

export default function* rootSaga() {
    yield fork(kioskModeSaga);
    yield fork(volunteersSaga);
    yield fork(timesheetSaga);
    yield fork(notificationsSaga);
}
import _ from 'lodash';
import { createSelector } from 'reselect';

export const kioskStatus = (state) => _.get(state, 'kiosk.status', 'inactive');
export const isKioskMode = (state) =>  kioskStatus(state) !== 'inactive';
export const lastError = (state) => _.get(state, 'kiosk.error');

export const getOpportunity = (state) => _.get(state, 'kiosk.opportunity');
export const getOpportunityId = (state) => _.get(state, 'kiosk.opportunity.id');
export const opportunityName = (state) => _.get(state, 'kiosk.opportunity.name');

export const organizationName = (state) => _.get(state, 'kiosk.opportunity.organization.name');
export const getOrganizationId = (state) => _.get(state, 'kiosk.opportunity.organization.id');

export const getVolunteers = (state) => _.get(state, 'kiosk.volunteers', []);
export const getVolunteeringRoles = (state) => _.get(state, 'kiosk.volunteeringRoles', []);
export const getTimesheetMode = (state) => _.get(state, 'kiosk.timesheetMode', 'check-in');

export const getTimesheetList = createSelector(
    getVolunteers,
    getTimesheetMode,
    (volunteers, mode) => {
        let test = mode === 'check-in' ? t => t == null : t => t != null;
        return _.chain(volunteers)
            .filter(v => test(v.checkedInAt))
            .orderBy(['user.givenName', 'user.familyName'])
            .value();
    })

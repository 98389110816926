import _ from 'lodash';
import React, { useCallback } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#FFCD00'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  gridAction: {
    width: 250,
    marginLeft: 10
  },
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    maxHeight: 32,
    marginRight: '10px'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

export default function TableToolbar(props) {
    const { query, onQueryChange, actionMenu } = props;

    const classes = useStyles();
    const handleQueryChange = useCallback( event => onQueryChange(event.target.value), [onQueryChange])
    const handleClearQuery = useCallback( () => onQueryChange(''), [onQueryChange]);

    return (
        <Toolbar className={classes.container}>
            <div className={classes.gridAction}>
              {actionMenu && actionMenu}
            </div>
            <div className={classes.spacer}></div>
            <div className={classes.actions}>
                <Paper className={classes.searchContainer}>
                    <InputBase
                        className={classes.input}
                        placeholder="Search"
                        value={query}
                        onChange={handleQueryChange}
                    />
                    { _.isEmpty(query) 
                        ? <IconButton 
                            className={classes.iconButton}  
                        >
                            <Search />
                        </IconButton>
                        : <IconButton
                            className={classes.iconButton}
                            onClick={handleClearQuery}
                        >
                            <Clear />
                        </IconButton>
                    }
                </Paper>
            </div>
        </Toolbar>
    )
}

import { takeEvery, put } from 'redux-saga/effects';
import actions from '../actions';
import { ACTIVITIES_REQUEST } from '../actions/activities';
import { API, Auth as AuthAmplify } from 'aws-amplify';
import { get } from 'lodash';

export function* requestActivities() {
  try {
    const user = yield AuthAmplify.currentAuthenticatedUser();
    const permissions = JSON.parse(get(user, 'attributes.custom:permissions'));
    const organizationId = get(permissions, 'managedOrganization.id');
    const activities = yield API.get('civicChampsApi', `/organizations/${organizationId}/activities`);
    yield put(actions.activities.success(activities));
  } catch (e) {
    yield put(actions.activities.failure());
  }
}

export default function* activitiesSaga() {
  yield takeEvery(ACTIVITIES_REQUEST, requestActivities);
}

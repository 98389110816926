import _ from 'lodash';

class ActivitySource {
    constructor(props) {
        Object.defineProperties(this, _.mapValues(props, (value) => ({ value })))
    }
}

//TODO replace strings with the enums from this directory!
const sources = _.mapValues({ 
    MOBILE_CHECK_IN: {
        id: 1,
        name: 'mobile check-in',
        source: 'app',
        actor: 'self',
        entryMethod: 'timesheet',
        entryType: 'initial entry'
    },
    LOG_OTHER_HOURS: {
        id: 2,
        name: 'log other hours',
        source: 'app',
        actor: 'self',
        entryMethod: 'log other hours',
        entryType: 'initial entry'
    },
    ADMIN_MOBILE_CHECK_IN: {
        id: 3,
        name: 'admin mobile check-in',
        source: 'app',
        actor: 'admin',
        entryMethod: 'timesheet',
        entryType: 'initial entry'
    },
    KIOSK_CHECK_IN: {
        id: 4,
        name: 'kiosk check-in',
        source: 'kiosk',
        actor: 'self',
        entryMethod: 'timesheet',
        entryType: 'initial entry'
    },
    MOBILE_CHECK_IN_EDIT: {
        id: 5,
        name: 'mobile check-in edit',
        source: 'app',
        actor: 'self',
        entryMethod: 'timesheet',
        entryType: 'edited'
    },
    LOG_OTHER_HOURS_EDIT: {
        id: 6,
        name: 'log other hours edit',
        source: 'app',
        entryMethod: 'log other hours',
        entryType: 'edited'
    },
    AUTOMATIC_CHECK_OUT: {
        id: 7,
        name: 'automatic check-out',
        source: 'system',
        actor: 'scheduled process',
        entryMethod: 'timesheet',
        entryType: 'initial entry'
    },
    ADMIN_TIMESHEET_EDIT: {
        id: 8,
        name: 'admin timesheet edit',
        source: 'web',
        actor: 'admin',
        entryMethod: 'timesheet',
        entryType: 'edited'
    },
    ADMIN_LOG_OTHER_HOURS_EDIT: {
        id: 9,
        name: 'admin log outher hours edit',
        source: 'web',
        actor: 'admin',
        entryMethod: 'log other hours',
        entryType: 'edited'
    }
}, config => new ActivitySource(config) );

const keyById = _.reduce(
    sources, 
    (keyById, source, key) => { 
        keyById[source.id] = key; 
        return keyById;
    },
    {}
)

const getById = (id) => keyById[id]

export default Object.freeze({
    ...sources,
    getById
});
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    padding: '3em 0'
  },
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  addButton: {
    width: 'fit-content',
    alignSelf: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalTitle: {
    background: '#FFCD00',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  modalContainer: {
    display: 'flex',
  },
  modalLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalRightColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },

}));

export default useStyles;

import { fork, take, takeEvery, call, put, select } from 'redux-saga/effects';
import { API } from 'aws-amplify';

import { GET_TEAM_MEMBERS, getTeamMembersSucceeded, getTeamMembersFailed } from './actions';
import { AUTH_INITIALIZED } from '../../auth/actions';
import { currentOrganizationId } from '../../auth/selectors';

export default function* teamSaga() {
    yield fork(watchGetTeamMembers);
}

export function* watchGetTeamMembers() {
    yield take(AUTH_INITIALIZED);
    yield takeEvery(GET_TEAM_MEMBERS, onGetTeamMembers);
}

export function* onGetTeamMembers() {
    try {
        let members = yield call(fetchTeamMembers);
        yield put( getTeamMembersSucceeded(members) );
    } catch(error) {
        yield put( getTeamMembersFailed(error) );
    }
}

function* fetchTeamMembers() {
    let orgId = yield select(currentOrganizationId);
    let url = `/organizations/${orgId}/members?reporting=true`;
    return yield call([API, 'get'], 'civicChampsApi', url);
}
import React from 'react';

import format from 'utils/format';

export const RightAlign = ({ children }) => (
    <div style={{ textAlign: 'right', marginRight: '10px' }}>
        {children}
    </div>
);
export const LeftAlign = ({ children, width }) => (
    <div style={{ textAlign: 'left', width: width }}>
        {children}
    </div>
);

export const DateCell = ({ cell }) => <RightAlign>{format.date(cell.value)}</RightAlign>
export const DateTimeCell = ({ cell }) => <RightAlign>{format.datetime(cell.value)}</RightAlign>
export const HoursCell = ({ cell }) => <RightAlign>{format.hours(cell.value)}</RightAlign>
export const NumberCell = ({ cell }) => <RightAlign>{format.number(cell.value)}</RightAlign>
export const FixedPointCell = ({ cell }) => <RightAlign>{format.fixedPoint(cell.value, 1)}</RightAlign>
export const IntegerCell = ({ cell }) => <RightAlign>{format.fixedPoint(cell.value, 0)}</RightAlign>

export const PreFormattedCell = ({ cell }) => <LeftAlign width={cell.value.width}>{cell.value.display}</LeftAlign>
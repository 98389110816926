import { GET_TEAM_MEMBERS, GET_TEAM_MEMBERS_SUCCEEDED, GET_TEAM_MEMBERS_FAILED } from './actions'
import getErrorMessage from '../../utils/getErrorMessage';

const defaultState = {
    members: [],
    getTeamMembers: { running: false, error: null }
}

export default function(state=defaultState, action) {
    switch(action.type) {
        case GET_TEAM_MEMBERS:
            return {
                ...state,
                getTeamMembers: { running: true, error: null }
            }
        
        case GET_TEAM_MEMBERS_SUCCEEDED:
            return {
                ...state,
                members: action.payload.members,
                getTeamMembers: { running: false, error: null }
            }
        
        case GET_TEAM_MEMBERS_FAILED:
            return {
                ...state,
                getTeamMembers: {
                    running: false,
                    error: getErrorMessage(action.payload.error)
                }
            }
        
        default:
            return state;
    }
} 
import { connect } from 'react-redux';

import { getTeamMembers } from '../../actions';
import * as selectors from '../../selectors';
import MyTeam from './MyTeam';

const mapStateToProps = state => ({
    loading: selectors.isLoading(state),
    members: selectors.getTeamMembers(state) 
})

const mapDispatchToProps = dispatch => ({
    getTeamMembers: () => dispatch( getTeamMembers() )
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);

import Amplify, { Auth } from 'aws-amplify';

const getAuthorizationToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW,
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'civicChampsApi',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => ({ Authorization: await getAuthorizationToken() })
      }
    ]
  }
});

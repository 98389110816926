import React, { useState, useMemo, useCallback } from 'react';
import { 
    useTable, 
    useSortBy, 
    useFilters, 
    usePagination,
    useRowSelect,
    useTableState
} from 'react-table';
import { 
    Table, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell,
    TablePagination 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const pageSizeOptions = [10, 25, 50, 100];

const useStyles = makeStyles( theme => ({
    sortAsc: {
        boxShadow: 'inset 0 3px 0 0 rgba(0,0,0,.6);'
    },
    sortDesc: {
        boxShadow: 'inset 0 -3px 0 0 rgba(0,0,0,.6);'
    },
    selectedRow: {
        background: 'rgb(125, 152, 218)'
    },
    selectedCell: {
        color: 'white'
    },
    header: {
        cursor: 'pointer'
    },
    cell: {
        flex: '100 0 auto',
        width: '100px'
    }
}));

const handleToggleSortBy = column => {
    let newSort = column.isSorted ? !column.isSortedDesc : false;
    column.toggleSortBy(newSort);
}

const getClampedPageIndex = (totalItemCount, itemsPerPage, pageIndex) => {
    let pageCount =  Math.floor((totalItemCount + itemsPerPage - 1) / itemsPerPage);
    let minimumPageIndex = 0;
    let maximumPageIndex = Math.max(0, pageCount - 1);

    return Math.min(Math.max(minimumPageIndex, pageIndex), maximumPageIndex);
}

export default function DataTable(props) {
    const { data, onRowAction } = props;

    // Manually manage pageIndex to avoid losing the current index when elements are updated/removed
    const [ pageIndexOverride, setPageIndexOverride ] = useState(0);
    const initialState = { pageIndex: pageIndexOverride };
    const stateOverrides = useMemo(() => ({ pageIndex: pageIndexOverride }));
    const state = useTableState(initialState, stateOverrides);

    const table = useTable({
            ...props,
            state
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect
    );

    //pagination
    const [ tableState, ] = table.state;
    const handleChangePage = useCallback( 
        (_, selectedPage) => {

            let pageIndex = getClampedPageIndex(data.length, tableState.pageSize, selectedPage);
            setPageIndexOverride(pageIndex);
        },
        [tableState, data]
    );

    const handleChangePageSize = useCallback(
        event => {
            table.setPageSize(event.target.value);
            
            let pageIndex = getClampedPageIndex(data.length, event.target.value, pageIndexOverride);
            setPageIndexOverride(pageIndex);
        },
        [table, data, pageIndexOverride]
    );

    // sorting
    const classes = useStyles();

    const getSortStyle = useCallback( column => {
        if(column.isSorted) {
            return column.isSortedDesc ? classes.sortDesc : classes.sortAsc; 
        } 

        return null;
    }, [classes]);

    // row select
    const handleRowSelect = useCallback( (row) => {
        table.toggleRowSelectedAll(false);
        row.toggleRowSelected(!row.isSelected);

        if(onRowAction) onRowAction(row);
    }, [table, onRowAction]);

    return (<>
        <Table {...table.getTableProps()}>
            <TableHead>
                {table.headerGroups.map( headerGroup => (
                    <TableRow 
                        {...headerGroup.getHeaderGroupProps()}
                        className={classes.header}
                    >
                        { headerGroup.headers.map( column => (
                            <TableCell 
                                {...column.getHeaderProps()}
                                onClick={() => handleToggleSortBy(column)}
                                className={getSortStyle(column)}
                            >
                                {column.render('Header')}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                { table.page.map( (row, i) => 
                    table.prepareRow(row) || (
                    <TableRow 
                        {...row.getRowProps()}
                        className={row.isSelected ? classes.selectedRow : null}
                        onClick={() => handleRowSelect(row)}
                    >
                        { row.cells.map( cell => (
                            <TableCell 
                                {...cell.getCellProps()}
                                className={row.isSelected ? classes.selectedCell : null}
                            >
                                {cell.render('Cell')}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <TablePagination 
            component="div"
            count={data.length} 
            page={tableState.pageIndex}
            onChangePage={handleChangePage}
            rowsPerPage={tableState.pageSize}
            rowsPerPageOptions={pageSizeOptions}
            onChangeRowsPerPage={handleChangePageSize}
        />
    </>)
}
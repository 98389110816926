import moment from 'moment';
import { get, meanBy, sumBy, isEmpty } from 'lodash';
import React, { useState, useMemo, useCallback } from 'react';
import { Grid, Typography, Hidden, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

import Loading from 'components/Loading';
import Card from 'components/Card';
import ObjectTable from 'components/table/ObjectTable';
import { PreFormattedCell, FixedPointCell } from 'components/table/cell';
import { useCurrentOrg } from 'auth/hooks';
import { useQuery } from 'api/hooks';
import { GET_ACTIVITIES } from '../queries'
import useMiniSearch from 'utils/useMiniSearch';
import format from 'utils/format';
import activitySources from 'tracking/enum/activitySources';
import { LOG_OTHER_HOURS } from 'tracking/enum/entryMethod';
import DeleteActivityDialog from './DeleteActivityDialog';
import EditActivityDialog from './EditActivityDialog';

const getEntryMethod = (activity) => {
  let source = activitySources[activity.occurredAtSource];
  return get(source, 'entryMethod', LOG_OTHER_HOURS);
}

const getCheckInDate = (activity) => {
  let display = getEntryMethod(activity) === LOG_OTHER_HOURS
    ? format.date(activity.occurredAt)
    : format.datetime(activity.occurredAt);
  
  return { display: display, value: activity.occurredAt, width: '10em' };
}

const useActivityColumns = (actions) => 
    useMemo( () => {
        const { onDelete, onEdit } = actions;

        const ActivityActionCell = ({ cell }) => {
            const activity = cell.row.original;

            const handleDelete = e => {
                if(e) e.stopPropagation();
                onDelete(activity);
            }

            const handleEdit = e => {
                if(e) e.stopPropagation();
                onEdit(activity);
            }

            return <>
                <Tooltip title="Edit">
                    <IconButton onClick={handleEdit} size="small">
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton onClick={handleDelete} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
        } 

        return [
            { Header: 'First Name', accessor: 'user.givenName' },
            { Header: 'Last Name', accessor: 'user.familyName' },
            { Header: 'Opportunity', accessor: 'opportunity.name' },
            { Header: 'Activity', accessor: 'volunteeringRole.name' },
            {
                Header: 'Check-In',
                accessor: getCheckInDate,
                Cell: PreFormattedCell,
                sortMethod: (a, b) => {
                    let dateA = moment(a.value);
                    let sortA = dateA.isValid() ? dateA.valueOf() : 0;
                    let dateB = moment(b.value);
                    let sortB = dateB.isValid() ? dateB.valueOf() : 0;

                    return sortA - sortB;
                },
            },
            {
                Header: 'Hours',
                accessor: 'hoursVolunteered',
                Cell: FixedPointCell
            },
            {
                id: 'actions',
                Cell: ActivityActionCell
            }
        ]
    }, [actions]);

const useStyles = makeStyles(() => ({
  cardsWrapper: {
    padding: '3em 0 '
  }
}));

const headers = [
    { label: 'First Name', key: 'user.givenName' },
    { label: 'Last Name', key: 'user.familyName' },
    { label: 'Opportunity', key: 'opportunity.name' },
    { label: 'Activity', key: 'volunteeringRole.name' },
    { label: 'Check-Ins', key: 'volunteering.totalCheckIns' },
    { label: 'Last Activity', key: 'volunteering.latestActivity' },
    { label: 'Hours', key: 'hoursVolunteered' }
];

const searchConfig = {
    fields: ['user.givenName', 'user.familyName', 'opportunity.name', 'volunteeringRole.name'],
    extractField: get,
    processTerm: (term, field)  => term.toLowerCase(),
    searchOptions: {
        processTerm: term => term.toLowerCase(),
        prefix: true
    }
}

function Activities(props) {
    const organization = useCurrentOrg();
    const queryParams = useMemo(() => ({ variables: { organization } }), [organization]);
    const { data: activities, loading } = useQuery(GET_ACTIVITIES, queryParams); //TODO add in 'error' and 'refresh'

    const search = useMiniSearch(activities, searchConfig);

    //TODO these should probably be their own routes rather than mucking up this component
    const [activityToEdit, setActivityToEdit ] = useState(null);
    const [activityToDelete, setActivityToDelete] = useState(null);
    const actions = useMemo( () => ({
        onEdit: setActivityToEdit,
        onDelete: setActivityToDelete
    }), [setActivityToEdit, setActivityToDelete]);

    const columns = useActivityColumns(actions);
    const activitiesCards = useMemo(() => {
        const averageHours = meanBy(activities, item => item.hoursVolunteered) || 0;
        const totalHours =  sumBy(activities, item => item.hoursVolunteered) || 0;

        return [{
            header: 'Total Activities',
            body: `${format.count(activities)}`
        }, {
            header: 'Average Activity Length',
            body: `${format.hours(averageHours)}`
        }, {
            header: 'Total Hours',
            body: `${format.hours(totalHours)}`
        }];
    }, [activities]);

    const classes = useStyles();

    const handleCloseDeleteDialog = useCallback( () => { setActivityToDelete(null) }, [setActivityToDelete]);
    const handleCloseEditDialog = useCallback( () => { setActivityToEdit(null) }, [setActivityToEdit]);

    return (
        <div className={classes.activityWrapper}>
            <Grid container>
                <Typography gutterBottom variant="h4">
                    Activities
                </Typography>
            </Grid>
            {!loading || !isEmpty(activities) ? (
                <>
                <Hidden only="sm">
                    <Grid container justify="center" spacing={4} direction={'row'} className={classes.cardsWrapper}>
                    {activitiesCards.map((item, index) => (
                        <Card key={index} data={item} />
                    ))}
                    </Grid>
                </Hidden>
                <ObjectTable 
                    columns={columns} 
                    search={search}
                    csvHeaders={headers}
                    exportFilename="activities.csv"
                />
                </>
            ) : (
                <Loading />
            )}
            <DeleteActivityDialog 
                key={`delete-${activityToDelete ? activityToDelete.id : 'none'}`}
                open={!!activityToDelete} 
                onClose={handleCloseDeleteDialog}
                activity={activityToDelete}
            />
            <EditActivityDialog
                key={`edit-${activityToEdit ? activityToEdit.id : 'none'}`}
                open={!!activityToEdit}
                onClose={handleCloseEditDialog}
                activity={activityToEdit}
            />
        </div>
    );
}

export default Activities;
//import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';

import DataTable from '../DataTable'; //TODO move this component!
import TableToolbar from './TableToolbar';
import ActionMenu from 'components/menu/ActionMenu';
import ExportCsvMenuItem from './ExportCsvMenuItem';

/*
import { fuzzyMatch } from './filters';

const filterTypes = { fuzzyMatch };

const useTableSearch = (columns, searchOnColumns, query) => {
    const updatedColumns = useMemo( () => 
        _.map(columns, column => {
            if( !searchOnColumns.includes(column.id || column.accessor) ) return column;

            let newColumn = _.clone(column);
            newColumn.filter = 'fuzzyMatch';

            return newColumn;
        }), 
        [columns, searchOnColumns])

    const filterState = useMemo( () => 
        _.reduce(searchOnColumns, (filters, id) => {
            filters[id] = query;
            return filters;
        }, {}), 
        [searchOnColumns, query]
    );

    return useMemo( () => ({
        columns: updatedColumns,
        filterState
    }), [updatedColumns, filterState])
}
*/

export default function ObjectTable(props) {
    const { columns, search, csvHeaders, exportFilename } = props;

    const [query, setQuery] = useState('');
    const filteredData = useMemo(() => search(query), [search, query]); //TODO this isn't very idiomatic...
                                                                        // promote table state etc instead?

            
    return (<Paper>
        <TableToolbar
            query={query}
            onQueryChange={setQuery}
            actionMenu={(
                <ActionMenu>
                    <ExportCsvMenuItem 
                        data={filteredData}
                        headers={csvHeaders}
                        filename={exportFilename}
                    />
                </ActionMenu>
            )}
        />
        <DataTable 
            columns={columns} 
            data={filteredData} 
        />
    </Paper>)
}
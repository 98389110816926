import { meanBy, sumBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ObjectTable from '../../components/table/ObjectTable';
import { FixedPointCell, DateTimeCell, IntegerCell } from 'components/table/cell';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import format from 'utils/format';
import useMiniSearch from 'utils/useMiniSearch';

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    padding: '3em 0',
  },
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  addButton: {
    width: 'fit-content',
    alignSelf: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalTitle: {
    background: '#FFCD00',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  modalContainer: {
    display: 'flex',
  },
  modalLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalRightColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },


}));

const columns = [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Event Start',
    accessor: 'startsAt',
    Cell: DateTimeCell
  },
  {
    Header: 'Event End',
    accessor: 'endsAt',
    Cell: DateTimeCell
  },
  {
    Header: 'Hours',
    accessor: 'totalVolunteerHours',
    Cell: FixedPointCell
  },
  {
    Header: 'Check-ins',
    accessor: 'totalCheckIns',
    Cell: IntegerCell
  }
];

const headers = [
  {
    label: 'Name',
    key: 'name' // String-based value accessors!
  },
  {
    label: 'Event Start',
    key: 'startsAt'
  },
  {
    label: 'Event End',
    key: 'endsAt'
  },
  {
    label: 'Hours', // Custom header components!
    key: 'totalVolunteerHours'
  },
  {
    label: 'Check-ins', // Custom header components!
    key: 'totalCheckIns'
  }
];

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}>
      <DialogTitle id="simple-dialog-title" className={classes.modalTitle}>Add Opportunity</DialogTitle>
      <Paper className={classes.paper}>
        <Grid container spacing={3} direction="row" wrap="nowrap">
          <Grid container direction="column">
            <Grid item xs>
              <TextField
                id="outlined-with-placeholder"
                label="Name"
                placeholder="Name"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <TextField
                  id="datetime-local"
                  label="Start:"
                  type="datetime-local"
                  defaultValue="2017-07-31T10:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs>
              <TextField
                  id="datetime-local"
                  label="End:"
                  type="datetime-local"
                  defaultValue="2017-07-31T12:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs></Grid>
            <TextField
              id="outlined-multiline-static"
              label="Description:"
              multiline
              rows="4"
              defaultValue="Description of the event"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid container direction="column">
            <TextField
                id="outlined-with-placeholder"
                label="Location"
                placeholder="Location"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
          </Grid>
        </Grid>
        <br></br>
        <Button variant="outlined" color="primary" className={classes.addButton}>
          Add Opportunity
        </Button>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
      processTerm: term => term.toLowerCase(),
      prefix: true
  }
}

function Opportunities(props) {
  const { getOpportunities, opportunities = [], loading } = props;
  const classes = useStyles();

  const search = useMiniSearch(opportunities, searchConfig);

  const opportunitiesCards = useMemo(() => {
    const totalVolunteerHours = sumBy(opportunities, 'totalVolunteerHours') || 0;
    const averageHours = meanBy(opportunities, 'totalVolunteerHours') || 0;

    return [{
      header: 'Opportunities',
      body: `${format.count(opportunities)} `
    },
    {
      header: 'Total Hours',
      body: `${format.hours(totalVolunteerHours)} `
    },
    {
      header: 'Avg. Opportunity Hours',
      body: `${format.hours(averageHours)}`
    }]
  }, [opportunities]);

  useEffect(() => { getOpportunities() }, [getOpportunities]);

  return (
    <div className={classes.activityWrapper}>
      <Grid container>
        <Typography gutterBottom variant="h4">
          Opportunities
        </Typography>
    </Grid>
      {!loading || opportunities.length ? (
        <>
          <div className={classes.root}>
            <Hidden only="sm">
              <Grid container justify="center" spacing={4} className={classes.cardsWrapper}>
                {opportunitiesCards.map((item, index) => (
                  <Card key={index} data={item} />
                ))}
              </Grid>
            </Hidden>
          </div>
          <ObjectTable
            columns={columns}
            data={opportunities}
            search={search}
            csvHeaders={headers}
            exportFilename="opportunities.csv"
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Opportunities;

import { takeEvery, put } from 'redux-saga/effects';
import actions from '../actions';
import {
  OPPORTUNITIES_REQUEST,
  OPPORTUNITIES_BY_DATE_RANGE_REQUEST,
  VOLUNTEERS_BY_OPPORTUNITIES_REQUEST,
  OPPORTUNITIES_FILTER
} from '../actions/opportunities';
import { API, Auth as AuthAmplify } from 'aws-amplify';
import { get } from 'lodash';

export function* requestOpportunities({ payload }) {
  try {
    console.log(payload);
    const organization = yield AuthAmplify.currentAuthenticatedUser().then(user => 
      JSON.parse(get(user, 'attributes.custom:permissions'))
    );
    const id = get(organization, 'managedOrganization.id');

    const ops = yield API.get('civicChampsApi', `/organizations/${id}/opportunities?reporting=true`);
    yield put(actions.opportunities.success({ ...payload, opportunities: ops }));
  } catch (e) {
    yield put(actions.opportunities.failure());
  }
}

export function* requestOpportunitiesByDateRange({ payload }) {
  try {
    const organization = yield AuthAmplify.currentAuthenticatedUser().then(user =>
      JSON.parse(get(user, 'attributes.custom:permissions'))
    );
    const id = get(organization, 'managedOrganization.id');

    const list = yield API.get('civicChampsApi', `/organizations/${id}/opportunities?reporting=true&dateRange=${payload.dateRange}`);
    yield put(actions.opportunities.success(list));
  } catch (e) {
    yield put(actions.opportunities.failure());
  }
}


export function* requestVolunteersByOpportunities({ payload }) {
  try {
    const volunteers = yield API.get('civicChampsApi', `/opportunities/${payload}/active_volunteers`);
    yield put(actions.opportunities.volunteersByOpportunitySuccess(volunteers));
  } catch (e) {
    yield put(actions.opportunities.failure());
  }
}
export function* filterOpportunities({ payload }) {
  try {
    const organization = yield AuthAmplify.currentAuthenticatedUser().then(user =>
      JSON.parse(get(user, 'attributes.custom:permissions'))
    );
    const id = get(organization, 'managedOrganization.id');

    const ops = yield API.get('civicChampsApi', `/organizations/${id}/opportunities?reporting=true`);
    yield put(actions.opportunities.filterSuccess({ ...payload, opportunities: ops }));
  } catch (e) {
    yield put(actions.opportunities.failure());
  }
}

export default function* opportunititesSaga() {
  yield takeEvery(OPPORTUNITIES_REQUEST, requestOpportunities);
  yield takeEvery(OPPORTUNITIES_BY_DATE_RANGE_REQUEST, requestOpportunities);
  yield takeEvery(VOLUNTEERS_BY_OPPORTUNITIES_REQUEST, requestVolunteersByOpportunities);
  yield takeEvery(OPPORTUNITIES_FILTER, filterOpportunities);
}

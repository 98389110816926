import {
  OPPORTUNITIES_REQUEST,
  OPPORTUNITIES_SUCCESS,
  VOLUNTEERS_BY_OPPORTUNITIES_SUCCESS,
  OPPORTUNITIES_FILTER_SUCCESS
} from '../actions/opportunities';

export const defaultState = {
  opportunities: [],
  loading: false,
  volunteersByOpportunity: []
};

export default function config(state = defaultState, action) {
  switch (action.type) {
    case OPPORTUNITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        opportunities: action.payload.opportunities,
        loading: false
      };

    case VOLUNTEERS_BY_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        volunteersByOpportunity: action.payload,
        loading: false
      };
    case OPPORTUNITIES_FILTER_SUCCESS:
      if (action.payload.fromDate && action.payload.toDate) {
        const range = action.payload.opportunities.filter(item => {
          return (
            new Date(item.startsAt).getTime() > new Date(action.payload.fromDate).getTime() &&
            new Date(item.endsAt).getTime() < new Date(action.payload.toDate).getTime()
          );
        });
        console.log(range);
        return {
          ...state,
          opportunities: range
        };
      }
      return {
        ...state
      };
    default:
      return state;
  }
}

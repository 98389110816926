import _ from 'lodash';

export const GET_ACTIVITIES = {
    request: ({ organization }) => ({
        url: `/organizations/${organization.id}/activities`
    }),
    caching: {
        maxAge: '1 hour',
        staleAfter: '1 minute'
    }, 
    update: (queryConfig, state, result) => ({
        ...state,
        activities: result
    }),
    select: (queryConfig, state) => state.activities
}

export const DELETE_ACTIVITY = {
    request: ({ activity }) => ({
        method: 'del',
        url: `/activities/${activity.id}`
    }),
    update: (queryConfig, state) => {
        let id = _.get(queryConfig, 'variables.activity.id');
        return {
            ...state,
            activities: _.filter(state.activities, a => a.id !== id)
        }
    }
}

export const UPDATE_ACTIVITY = {
    request: ({ activityId, updates }) => ({
        method: 'put',
        url: `/activities/${activityId}`,
        config: { body: updates }
    }),
    update: (queryConfig, state, result) => ({
        ...state,
        activities: _.map(state.activities, a => a.id == result.id ? result : a),
    })
}